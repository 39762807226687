import React, { FC } from 'react';
import { Wrapper } from './styles';
import { Images } from 'utils/images';

interface Props {
  image: string
}

export const ImageBlock:FC<Props> = ({ image }) => {
  return (
    <Wrapper>
      <img src={Images[image]} alt="about-us" />
    </Wrapper>
  );
};

ImageBlock.defaultProps = {
  image: ''
};
