import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Breakpoints } from 'theme/breakpoints';
import { Colors } from 'theme/colors';

export const Wrapper = styled(Link)`
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    width: 80px;
    height: 100%;
    @media screen and (max-width: ${Breakpoints.mobile}) {
       width: 64px;
    }
  }
`;

export const LogoText = styled.h1`
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -2px;
  pointer-events: none;
  color: ${Colors.text.black};
  @media screen and (max-width: ${Breakpoints.mobile}){
    font-size: 28px;
  }
`;
