import styled from 'styled-components';
import { Breakpoints } from 'theme/breakpoints';

export const Wrapper = styled.div`
  padding: 50px 0;

  @media screen and (max-width: ${Breakpoints.tablet}) {
    padding: 32px 0 0 0;
  }
`;

export const Container = styled.div`
  & + & {
    margin-top: 50px;
       @media screen and (max-width: ${Breakpoints.tablet}) {
         margin-top: 32px;
       }
  }
`;
