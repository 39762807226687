import styled from 'styled-components';
import { Colors } from 'theme/colors';
import { TypographyTitle } from "theme/Thypography";
import { Breakpoints } from 'theme/breakpoints';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 110px;
  background-color: ${Colors.background.white};

  @media screen and (max-width: ${Breakpoints.mobile}) {
    padding-bottom: 60px;
  }
`;

export const Title = styled(TypographyTitle)`
  text-align: center;
  color: ${Colors.text.black};
  text-transform: uppercase;
`;
