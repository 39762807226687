import styled from "styled-components";
import { Breakpoints } from 'theme/breakpoints';

export const Button = styled.button`
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;

  img {
    width: 18px;
    height: 18px;
  }

  @media screen and (max-width: ${Breakpoints.tablet}) {
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-left: 0;
  }
`;

export const ButtonText = styled.span`
  font-size: 12px;
  font-weight: 600;
  margin-top: 4px;

  @media screen and (max-width: ${Breakpoints.tablet}) {
    margin-top: 0;
    margin-left: 4px;
  }
`;
