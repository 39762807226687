import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 200px;
  height: auto;
  padding: 12px;

  img {
    width: 100%;
    height: auto;
  }
`;
