import React, { FC } from 'react';
import { Wrapper, Container } from "./styles";
import { ContentItem } from 'components/Components/PortfolioSection/PortfolioContentItem/PortfolioContentItem';
import { PortfolioContentItem } from 'components/Components/PortfolioSection/PortfolioContentItem';
import { Animation } from 'common/Animation';

interface Props {
  data: ContentItem[]
}

export const PortfolioContent: FC<Props> = ({ data }) => {
  return (
    <Wrapper>
      {
        data.map((item, index) => {
          return (
            <Container key={index}>
              <Animation fadeInUp>
                <PortfolioContentItem item={item} />
              </Animation>
            </Container>
          );
        })
      }
    </Wrapper>
  );
};
