import styled from 'styled-components';
import { Breakpoints } from 'theme/breakpoints';

export const Wrapper = styled.div`
  flex:  0 0 70%;
  max-width: 70%;

  @media screen and (max-width: ${Breakpoints.mobile}) {
    flex:  0 0 100%;
    max-width: 100%;
  }
`;

export const Form = styled.form``;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  & + & {
    margin-top: 8px;
  }

  @media screen and (max-width: ${Breakpoints.mobile}) {
    flex-wrap: wrap;
    & + & {
      margin-top: 0;
    }
  }
`;

export const Col = styled.div`
  flex: 0 0 48.5%;
  max-width: 48.5%;

  & + & {
    margin-left: 3%;
  }

  @media screen and (max-width: ${Breakpoints.mobile}) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 6px;
    & + & {
      margin-left: 0;
    }
  }
`;

export const FullWithCol = styled.div`
  flex: 0 0 100%;
  max-width: 100%;

  @media screen and (max-width: ${Breakpoints.mobile}) {
    margin-top: 6px;
  }
`;

export const ActionsContainer = styled.div`
  margin-top: 12px;
  text-align: center;

  @media screen and (max-width: ${Breakpoints.mobile}) {
    button {
      width: 100%;
    }
  }
`;
