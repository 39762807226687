import styled from 'styled-components';
import { Breakpoints } from 'theme/breakpoints';
import { Images } from 'utils/images';

interface Props {
  background: string;
}

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${Breakpoints.mobile}) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  opacity: 1;
  transition: opacity ease .25s;

  & + & {
    margin-left: 12px;
  }

  &:hover {
    opacity: .6;
  }
`;

export const Icon = styled.div`
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: ${({ background }:Props) => `url("${Images.socials[background || '']}")` };
`;
