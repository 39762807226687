import styled from 'styled-components';
import { Colors } from "theme/colors";
import { Breakpoints } from "theme/breakpoints";

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 772px) {
    justify-content: center;
  }

  @media screen and (max-width: ${Breakpoints.mobile}) {

  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 0 0 33.33333%;

  @media screen and (max-width: 772px) {
    flex: 0 0 50%;
    padding-bottom: 30px;
  }
  @media screen and (max-width: ${Breakpoints.mobile}) {
    flex: 0 0 100%;
    padding-bottom: 0;
    & + & {
    margin-top: 60px;
    }
  }
`;

export const Label = styled.div`
  margin-bottom: 24px;
  font-size: 26px;
  font-weight: 600;
  line-height: 32px;
`;

export const SocialsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const TelegramLinksContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const TelegramLink = styled.a`
  display: block;
  width: 32px;
  height: 32px;
  transition: opacity ease .25s;
  &:hover {
    opacity: 0.7;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

export const EmailsContainer = styled.div``;

export const Link = styled.a`
  font-size: 18px;
  font-weight: 500;
  color: ${Colors.text.lightBlue};
  transition: opacity ease .25s;
  &:hover {
    opacity: .7;
  }
`;



