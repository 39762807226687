import React from 'react';
import { MainContainer } from 'wrappers/MainContainer';
import { Wrapper } from './styles';
import { OurClientsContent } from 'components/Components/OurClientsContent';
import { useTranslation } from "utils/i18n";
import { Animation } from 'common/Animation';
import { Client } from "../../Components/OurClientsContent/OurClientsMain/OurClientsMain";

export const OurClients = () => {
  const { t } = useTranslation();
  const header = {
    title: t('ourClientsTitle'),
    text: t('ourClientsText')
  };

  const clients: Client[] = [
    {
      icon: 'vertexFoodClient'
    },
    {
      icon: 'bitActiveClient'
    },
    {
      icon: 'lifeaddwiserClient'
    },
    {
      icon: 'wargamingClient'
    },
    {
      icon: 'catClient'
    },
    {
      icon: 'customertimesClient'
    },
    {
      icon: 'competenzlabClient',
      customStyle: {
        background: '#000000'
      }
    },
    {
      icon: 'xactlyClient'
    },
    {
      icon: 'propellerplanClient'
    }
  ];

  return (
    <Wrapper id="clients">
      <MainContainer>
        <Animation fadeInUp>
          <OurClientsContent
            header={header}
            clients={clients}
          />
        </Animation>
      </MainContainer>
    </Wrapper>
  );
};
