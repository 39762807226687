import styled from 'styled-components';
import { Breakpoints } from 'theme/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: -10px;

  @media screen and (max-width: ${Breakpoints.tablet}) {
    margin: 0;
    flex-wrap: wrap;
  }
`;
