import styled from 'styled-components';
import { Colors } from "theme/colors";
import { Breakpoints } from "theme/breakpoints";

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  margin: 200px 0 100px 0;
  background-color: ${Colors.background.white};
  @media screen and (max-width: ${Breakpoints.mobile}) {
    margin: 140px 0 80px 0;
  }
`;
