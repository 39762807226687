export const Colors = {
  text: {
    black: '#000000',
    lightBlue: '#00AEEF',
    grey: '#75809f',
    lightGrey: '#7A7A7A',
    darkGrey: '#54595F',
    error: '#AF4052'
  },
  background: {
    green: '#61ce70',
    white: '#ffffff',
    lightGreen: '#61ce52',
    lightGrey: '#94968a',
    darkWhite: '#f9f9f9',
    blue: '#0D2139',
    lightBlue: '#193250',
    darkIcon: '#36415c'
  }
};
