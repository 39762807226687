import React, { FC } from 'react';
import { Wrapper, LogoText } from './styles';
import { Images } from 'utils/images';
import { useTranslation } from "utils/i18n";

interface Props {
  logoText: string
}

export const Logo:FC<Props> = ({ logoText }) => {
  const { i18n } = useTranslation();
  const formedLinkByLanguage = (link:string):string => {
    return i18n.language === 'ru' ? `/ru${link}` : link;
  };

  return (
  <Wrapper to={formedLinkByLanguage('/')}>
    <img src={Images.logo} alt="Logo" />
    <LogoText>{logoText}</LogoText>
  </Wrapper>
  );
};
