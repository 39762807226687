import React, { FC } from 'react';
import { Wrapper } from './styles';
import { Animation } from "common/Animation";
import { MainContainer } from 'wrappers/MainContainer';
import { ContactsDataContent } from 'components/Components/ContactsDataContent';
import { Socials, Emails, Telegrams } from "components/Components/ContactsDataContent/ContactsDataContent";

interface Props {
  socials: Socials,
  emails: Emails,
  telegrams: Telegrams
}

export const ContactsDataSection:FC<Props> = ({ socials, emails, telegrams }) => {
  return(
    <Wrapper>
      <MainContainer>
        <Animation fadeInUp>
        <ContactsDataContent socials={socials} emails={emails} telegrams={telegrams} />
        </Animation>
      </MainContainer>
    </Wrapper>
  );
};
