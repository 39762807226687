import React, { FC } from 'react';
import { Wrapper, Text } from './styles';
import { SocialsBlock } from './SocialsBlock';
import { SocialItemType } from './SocialsBlock/SocialsBlock';
import { useTranslation } from "utils/i18n";

interface Props {
  socials: SocialItemType[]
}

export const FooterLeftBlock:FC<Props> = ({ socials }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <SocialsBlock socials={socials} />
      <Text>
        {t('footerAllRightsCompany')}
        <br />
        {t('footerAllRightsReserved')}
        <br />
        {t('footerCountryAndCity')}
      </Text>
    </Wrapper>
  );
};

FooterLeftBlock.defaultProps = {
  socials: []
};
