import styled from 'styled-components';
import { Colors } from 'theme/colors';
import { Breakpoints } from 'theme/breakpoints';

export const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  bottom: 0;
  height: 90px;
  width: 100%;
  z-index: 10;
  background-color: ${Colors.background.white};
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);

  @media screen and (max-width: ${Breakpoints.tablet}) {
    height: 70px;
  }
`;

export const Wrapper = styled.div`
   margin: 0 auto;
   width: 100%;
   padding: 0 30px;
   height: 100%;
`;
