import React, { useState } from 'react';
import { Wrapper, Header, Container, Block } from './styles';
import { useTranslation } from "utils/i18n";
import { MainContainer } from 'wrappers/MainContainer';
import { Animation } from 'common/Animation';
import { Accordion } from 'common/Accordion';
import { useMedia } from 'react-media';
import { Breakpoints } from 'theme/breakpoints';

export const ServicesSection = () => {
  const { t } = useTranslation();
  const [firstAccordionTab, setFirstAccordionTab] = useState<string | number>('');
  const [secondAccordionTab, setSecondAccordionTab] = useState<string | number>('');
  const isMobile = useMedia({ query: `(max-width: ${Breakpoints.mobile})` });
  const isLandscapeMobile = useMedia({ query: `(max-width: 920px) and (orientation: landscape)` });

  const accordionItems1 = [
    {
      id:'1',
      title: t('accordionTitleOne'),
      descriptions: [t('accordionDescriptionOne1'), t('accordionDescriptionOne2')],
      icon: 'dashboard'
    },
    {
      id:'2',
      title: t('accordionTitleTwo'),
      descriptions: [t('accordionDescriptionTwo1'), t('accordionDescriptionTwo2'), t('accordionDescriptionTwo3')],
      icon: 'percentage'
    },
    {
      id:'3',
      title: t('accordionTitleThree'),
      descriptions: [t('accordionDescriptionThree1'), t('accordionDescriptionThree2'), t('accordionDescriptionThree3')],
      icon: 'leadGeneration'
    },
    {
      id:'4',
      title: t('accordionTitleFour'),
      descriptions: [t('accordionDescriptionFour1'), t('accordionDescriptionFour2')],
      icon: 'forecast2'
    },
    {
      id:'5',
      title: t('accordionTitleFive'),
      descriptions: [t('accordionDescriptionFive1')],
      icon: 'focus'
    },
    {
      id:'6',
      title: t('accordionTitleSix'),
      descriptions: [t('accordionDescriptionSix1')],
      icon: 'sitemap'
    },
    {
      id:'7',
      title: t('accordionTitleSeven'),
      descriptions: [t('accordionDescriptionSeven1')],
      icon: 'visualDetection'
    },
    {
      id:'8',
      title: t('accordionTitleEight'),
      descriptions: [t('accordionDescriptionEight1')],
      icon: 'recommended'
    },
    {
      id:'9',
      title: t('accordionTitleNine'),
      descriptions: [t('accordionDescriptionNine1')],
      icon: 'emotion'
    },
    {
      id:'10',
      title: t('accordionTitleTen'),
      descriptions: [t('accordionDescriptionTen1')],
      icon: 'logistics'
    },
  ];

  const accordionItems2 = [
    {
      id:'11',
      title: t('accordionSecondTitleOne'),
      descriptions: [t('accordionSecondDescriptionOne1'), t('accordionSecondDescriptionOne2')],
      icon: 'webScraping'
    },
    {
      id:'12',
      title: t('accordionSecondTitleTwo'),
      descriptions: [t('accordionSecondDescriptionTwo1'), t('accordionSecondDescriptionTwo2'), t('accordionDescriptionTwo3')],
      icon: 'analysis'
    },
    {
      id:'13',
      title: t('accordionSecondTitleThree'),
      descriptions: [t('accordionSecondDescriptionThree1')],
      icon: 'chatBot'
    },
    {
      id:'14',
      title: t('accordionSecondTitleFour'),
      descriptions: [t('accordionSecondDescriptionFour1')],
      icon: 'knowledge'
    },
    {
      id:'15',
      title: t('accordionSecondTitleFive'),
      descriptions: [t('accordionSecondDescriptionFive1')],
      icon: 'network'
    },
    {
      id:'16',
      title: t('accordionSecondTitleSix'),
      descriptions: [t('accordionSecondDescriptionSix1')],
      icon: 'security'
    },
    {
      id:'17',
      title: t('accordionSecondTitleSeven'),
      descriptions: [t('accordionSecondDescriptionSeven1')],
      icon: 'gear'
    },
    {
      id:'18',
      title: t('accordionSecondTitleEight'),
      descriptions: [t('accordionSecondDescriptionEight1')],
      icon: 'data'
    },
    {
      id:'19',
      title: t('accordionSecondTitleNine'),
      descriptions: [t('accordionSecondDescriptionNine1')],
      icon: 'scheme'
    },
    {
      id:'20',
      title: t('accordionSecondTitleTen'),
      descriptions: [t('accordionSecondDescriptionTen1')],
      icon: 'webPorgamming'
    },
  ];

  const selectFirstAccordionTab = (newTab: number | string): void => {
    if(newTab === firstAccordionTab) {
      setFirstAccordionTab('');
      return;
    }
    setFirstAccordionTab(newTab);
  };

  const selectSecondAccordionTab = (newTab: number | string): void => {
    if(newTab === secondAccordionTab) {
      setSecondAccordionTab('');
      return;
    }
    setSecondAccordionTab(newTab);
  };

  return (
    <Wrapper id="services">
      <Animation fadeInUp>
        <Header>
          {t('servicesTitle')}
        </Header>
      </Animation>
      <MainContainer>
        <Animation fadeInUp>
          <Container>
            <Block>
              <Accordion items={!isMobile && !isLandscapeMobile ? accordionItems1 : [...accordionItems1,...accordionItems2]} activeTab={firstAccordionTab} onChange={selectFirstAccordionTab} />
            </Block>
            {
              !isLandscapeMobile && !isMobile
              ? <Block>
                  <Accordion items={accordionItems2} activeTab={secondAccordionTab} onChange={selectSecondAccordionTab} />
                </Block>
              : null
            }

          </Container>
        </Animation>
      </MainContainer>
    </Wrapper>
  );
};
