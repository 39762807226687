import React, { FC } from 'react';
import {
  Wrapper,
  Column,
  Label,
  SocialsContainer,
  EmailsContainer,
  Link,
  TelegramLinksContainer,
  TelegramLink
} from './styles';
import { ContactsSocial } from 'components/Components/ContactsSocialsContent/ContactsSocials/ContactsSocial';
import { Images } from 'utils/images';
import { Social } from "../ContactsSocialsContent/ContactsSocials/ContactsSocial/ContactsSocial";

export interface Socials {
  label: string,
  items: Social []
}

export interface Emails {
  label: string,
  items: string[]
}

export interface Telegrams {
  label: string,
  links: string[]
}

interface Props {
  socials: Socials,
  emails: Emails,
  telegrams: Telegrams
}

export const ContactsDataContent:FC<Props> = ({ socials, emails, telegrams }) => {
  return (
    <Wrapper>
      <Column>
        <Label>{emails.label}</Label>
        <EmailsContainer>
          {
            emails.items.map((email,index) => (
              <Link key={index} href={`mailto:${email}`}>
                {email}
              </Link>
            ))
          }
        </EmailsContainer>
      </Column>
      <Column>
        <Label>{telegrams.label}</Label>
        <TelegramLinksContainer>
          {
            telegrams.links.map((link) => (
              <TelegramLink key={link} rel="noopener noreferrer" target="_blank" href={link}>
                <img src={Images.socials.telegramIcon} alt="telegram" />
              </TelegramLink>
            ))
          }
        </TelegramLinksContainer>
      </Column>
      <Column>
        <Label>{socials.label}</Label>
        <SocialsContainer>
        {
          socials.items.map((social, index) => (
            <ContactsSocial
              icon={social.icon}
              link={social.link}
              social={social.social}
              customStyle={social.customStyle}
              key={index}
            />
          ))
        }
        </SocialsContainer>
      </Column>
    </Wrapper>
  );
};
