import React, { FC } from 'react';
import { Wrapper } from './styles';
import { Icon } from 'components/Components/TechnologiesContent/IconsBlock/Icon';

export interface Props {
  icons: string[]
}

export const IconsBlock:FC<Props> = ({ icons }) => {
  return (
    <Wrapper>
      {
        icons.map((icon, index) => (
          <Icon key={index} icon={icon} />
        ))
      }
    </Wrapper>
  );
};

IconsBlock.defaultProps = {
  icons: []
};
