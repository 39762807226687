import styled, { css, keyframes } from 'styled-components';
// @ts-ignore
import { fadeInDown } from 'react-animations';

interface Props {
  visible: boolean;
  fadeInUp?: boolean;
  fade?: boolean;
  fadeInDown?: boolean
}

const fadeInDownAnimation = keyframes(fadeInDown);

const newFadeInAnimation = keyframes`
    from {
      transform: translate3d(0,50px,0);
      opacity: 0;
    }

    to {
      transform: translate3d(0,0,0);
      opacity: 1;
    }
`;

export const Container = styled.div`
    opacity: ${(props: Props) => props.visible ? 1 : 0};
    transition: opacity ease-in-out .5s;

  ${(props: Props) => (props.visible && props.fadeInUp) && css`
    animation-duration: .5s;
    animation-fill-mode: both;
    animation-name: ${newFadeInAnimation};
  `};

  ${(props: Props) => (props.visible && props.fadeInDown) && css`
    animation: .5s ${fadeInDownAnimation};
  `};
`;
