import React, { FC } from 'react';
import { Route } from 'react-router-dom';

interface P {
  layout: any; // React.Component | FC | React.PureComponent;
  title?: string;
  component: any; // React.Component | FC | React.PureComponent;
  path: string;
  exact?: boolean;
}

const RouteWithLayout: FC<P> = (
  {
    path,
    title,
    layout: Layout,
    component: Component,
    ...otherProps
  }) => (
  <Route
    path={path}
    {...otherProps}
    render={(matchProps) => (
      <Layout title={title} key={path}>
        <Component {...matchProps} />
      </Layout>
    )}
  />
);

export default RouteWithLayout;
