export const sendGoogleAnalyticsMetrik = (action?:any, value?:any, data?:{[key:string]:string}) => {
  const values:{
    [key: string]: string
  } = {};
  if(value !== undefined) {
    values["event_name"] = value;
  }

  if(data && Object.keys(data).length > 0) {
    for (const [key, value] of Object.entries(data)) {
      values[key] = value;
    }
  }

  // @ts-ignore
  if (window && typeof window !== 'undefined' && typeof window.gtag !== 'undefined') {
    // @ts-ignore
    window.gtag('event', action, values);
  } else {
    // на случай, если локально статистика отключена
    console.log('event', action, values);
  }
};
