import React, { FC } from 'react';
import { Wrapper } from './styles';

interface Props {
  children: any;
}

export const MainContainer:FC<Props> = ({ children }) => (
  <Wrapper>
    {children}
  </Wrapper>
);


MainContainer.defaultProps = {
  children: undefined
};
