import React, { FC } from 'react';
import { Wrapper, Overlay, Name, Text, Technology } from './styles';

export interface ProjectType {
  name?: string,
  text?: string,
  technologies?: string,
  background?: string
}

interface Props {
  project: ProjectType
}

export const OurProject: FC<Props> = ({ project }) => {
    const { name, text, technologies, background } = project;
    return (
      <Wrapper background={background || ''} >
        <Name>
          {name}
        </Name>
        <Text>
          {text}
        </Text>
        <Technology>
          {technologies}
        </Technology>
        <Overlay />
      </Wrapper>
    );
  };

OurProject.defaultProps = {
  project: {} as ProjectType
};
