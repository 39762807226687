import React, { FC } from 'react';
import {
  Wrapper,
  LeftBlock,
  RightBlock,
  SubtitleBlock,
  Subtitle,
  Label,
  Title,
  Text,
  LinkContainer,
  LinkIcon,
  Link
} from './styles';
import { useTranslation } from "utils/i18n";
import { Images } from 'utils/images';

export interface ContentItem {
  subtitle?: string;
  title: string;
  text: string;
  buttonLink?: string;
  imageSrc: string;
  reversed: boolean;
  new: boolean;
}

interface Props {
  item: ContentItem
}

export const PortfolioContentItem: FC<Props> = ({ item }) => {
  const { t } = useTranslation();

  const createMarkup = (text: string): any => {
    return { __html: text };
  };

  return (
    <Wrapper>
      <LeftBlock reserved={item.reversed}>
        <SubtitleBlock>
          <Subtitle>{item.subtitle}</Subtitle>
          {
            item.new && <Label>{t('portfoliodDataNew')}</Label>
          }
        </SubtitleBlock>
        <Title>
          {item.title}
        </Title>
        <Text dangerouslySetInnerHTML={createMarkup(item.text)} />
        {
          item.buttonLink
            ? <LinkContainer href={item.buttonLink}>
              <LinkIcon>
                <img src={Images.listIcon} alt="list-icon" />
              </LinkIcon>
              <Link>
                {t('portfolioLinkText')}
              </Link>
            </LinkContainer>
            : null
        }
      </LeftBlock>
      <RightBlock reserved={item.reversed}>
        <img src={Images.portfolio[item.imageSrc]} alt={item.imageSrc} />
      </RightBlock>
    </Wrapper>
  );
};
