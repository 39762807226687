import logo from 'images/logo.svg';
import mainPageBackground from 'images/background-main.jpg';
import mainPageBackgroundMobile from 'images/background_mobile_iphone6.jpg';
import checkIcon from 'images/check-icon.svg';
import listIcon from 'images/list-icon.svg';
import aboutUsImage from 'images/about-us.jpg';
// Technologies
import css from 'images/technologies/css.svg';
import html from 'images/technologies/html.svg';
import js from 'images/technologies/js.svg';
import python from 'images/technologies/python.svg';
// NEW TECHNOLOGIES
import amazon from 'images/technologies/new-amazon.svg';
import apache from 'images/technologies/new-apache.svg';
import docker from 'images/technologies/new-docker.svg';
import linux from 'images/technologies/new-linux.svg';
import mongo from 'images/technologies/new-mongo.svg';
import mysql from 'images/technologies/new-mysql.svg';
import nginx from 'images/technologies/new-nginx.svg';
import numpy from 'images/technologies/new-numpy.svg';
import pandas from 'images/technologies/new-pandas.svg';
import power from 'images/technologies/new-power.svg';
import keras from 'images/technologies/new-new-keras.svg';
import matplotlib from 'images/technologies/new-new-matplotlib.svg';
import pytorch from 'images/technologies/new-new-pytorch.svg';
import scickit from 'images/technologies/new-new-scikit-learn.svg';
import scipy from 'images/technologies/new-new-scipy.svg';
import tensorflow from 'images/technologies/new-new-tensorflow.svg';
import theano from 'images/technologies/new-new-theano.svg';
import react from 'images/technologies/react.svg';
import vue from 'images/technologies/vue-js.svg';
import sass from 'images/technologies/sass.svg';
import typescript from 'images/technologies/typescript.svg';
import node from 'images/technologies/node-js.svg';
// SOCIALS
import instagramIcon from 'images/socials/instagram.svg';
import facebookIcon from 'images/socials/facebook.svg';
import linkedinIcon from 'images/socials/linkedin.svg';
import instagramDarkIcon from 'images/socials/instagram-dark.svg';
import facebookDarkIcon from 'images/socials/facebook-dark.svg';
import linkedinDarkIcon from 'images/socials/linkedin-dark.svg';
import viberIcon from 'images/socials/viber.svg';
import telegramIcon from 'images/socials/telegram.svg';
import whatsappIcon from 'images/socials/whatsapp.svg';
import youtubeIcon from 'images/socials/youtube.svg';
import tiktokIcon from 'images/socials/tiktok.svg';
import youtubeDarkIcon from 'images/socials/youtube-dark.svg';
import tiktokDarkIcon from 'images/socials/tiktok-dark.svg';

import rusFlag from 'images/rus-flag.svg';
import engFlag from 'images/eng-flag.svg';
import earth from 'images/earth.svg';
// SERVICES ICONS
import leadGeneration from 'images/services-icons/leading.png';
import visualDetection from 'images/services-icons/face-detection.png';
import security from 'images/services-icons/security.png';
import focus from 'images/services-icons/focus.png';
import sitemap from 'images/services-icons/site-map.png';
import emotion from 'images/services-icons/emotion.png';
import forecast2 from 'images/services-icons/forecast2.png';
import logistics from 'images/services-icons/logistics.png';
import gear from 'images/services-icons/gear.png';
import recommended from 'images/services-icons/recommended.png';
import knowledge from 'images/services-icons/knowledge.png';
import chatBot from 'images/services-icons/chat bot.png';
import webPorgamming from 'images/services-icons/web-programming.png';
import data from 'images/services-icons/data.png';
import webScraping from 'images/services-icons/web scraping.png';
import network from 'images/services-icons/network.png';
import scheme from 'images/services-icons/scheme.png';
import dashboard from 'images/services-icons/dashboard.png';
import percentage from 'images/services-icons/percentage.png';
import analysis from 'images/services-icons/analysis2.png';
// PROJECTS
import vertexFoods from 'images/projects/vertex-foods.jpg';
import lifeaddwiser from 'images/projects/lifeaddwiser.jpg';
import bitActive from 'images/projects/bit-active.jpg';
import vivDev from 'images/projects/viv-dev.jpg';
// CLIENTS
import vertexFoodClient from 'images/clients/vertex-food.png';
import bitActiveClient from 'images/clients/bit-active.png';
import lifeaddwiserClient from 'images/clients/lifeaddwiser.png';
import wargamingClient from 'images/clients/wargaming.png';
import catClient from 'images/clients/cat.png';
import customertimesClient from 'images/clients/customertimes.svg';
import competenzlabClient from 'images/clients/competenzlab.webp';
import propellerplanClient from 'images/clients/propellerplan.png';
import xactlyClient from 'images/clients/xactly.png';
// PORTFOLIO
import dataStorage from 'images/services/data-storage-1.jpg';
import businessProcess from 'images/services/business-processes-2.jpg';
import dataVisualiztion from 'images/services/data-visualization-3.jpg';
import forecastingSystems from 'images/services/forecasting-systems-4.jpg';
import recommendationSystems from 'images/services/recommendation-systems-5.jpg';
import intelligentChatBots from 'images/services/chat-bot-6.jpg';

// OTHERS
import emailIcon from 'images/email-icon.svg';
import sharePostIcon from 'images/share-post-icon.svg';
interface Images {
  [key: string]: string | any
}

export const Images: Images = {
  logo,
  mainPageBackground,
  mainPageBackgroundMobile,
  checkIcon,
  emailIcon,
  sharePostIcon,
  listIcon,
  aboutUsImage,
  technologies: {
    css,
    html,
    js,
    python,
    // NEW TECHNOLOGIES
    theano,
    tensorflow,
    scipy,
    scickit,
    pytorch,
    matplotlib,
    amazon,
    apache,
    docker,
    linux,
    mongo,
    mysql,
    nginx,
    numpy,
    pandas,
    power,
    keras,
    react,
    vue,
    sass,
    typescript,
    node

  },
  clients: {
    vertexFoodClient,
    bitActiveClient,
    lifeaddwiserClient,
    wargamingClient,
    catClient,
    customertimesClient,
    competenzlabClient,
    propellerplanClient,
    xactlyClient
  },
  projects: {
    vertexFoods,
    lifeaddwiser,
    bitActive,
    vivDev
  },
  socials: {
    instagramIcon,
    facebookIcon,
    linkedinIcon,
    instagramDarkIcon,
    facebookDarkIcon,
    linkedinDarkIcon,
    viberIcon,
    telegramIcon,
    whatsappIcon,
    youtubeIcon,
    tiktokIcon,
    youtubeDarkIcon,
    tiktokDarkIcon
  },
  rusFlag,
  engFlag,
  earth,
  services: {
    leadGeneration,
    visualDetection,
    security,
    focus,
    sitemap,
    emotion,
    forecast2,
    logistics,
    gear,
    recommended,
    knowledge,
    chatBot,
    webPorgamming,
    data,
    webScraping,
    network,
    scheme,
    dashboard,
    percentage,
    analysis
  },
  portfolio: {
    dataStorage,
    businessProcess,
    dataVisualiztion,
    forecastingSystems,
    recommendationSystems,
    intelligentChatBots
  }
};
