import React, { FC } from 'react';
import { Title,Description, Wrapper, Text } from './styles';

interface Props {
  title?: string,
  description?: string,
  text?: string
}

export const TextBlock:FC<Props> = ({ title,description,text }) => {

  return (
    <Wrapper>
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}
      {text && <Text>{text}</Text>}
    </Wrapper>
  );
};

TextBlock.defaultProps = {
  title: '',
  description: '',
  text: ''
};
