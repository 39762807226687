import React, { FC, useState } from 'react';
import {
  Wrapper,
  ButtonContainer,
  MobileButton,
  MobileButtonIcon,
  Background
} from './styles';
import { Button } from 'common/Button';
import { SubscriptionDialog } from 'components/Dialogs/SubscriptionDialog';
import { NotifyDialog } from 'components/Dialogs/NotifyDialog';
import { useTranslation } from "utils/i18n";
import { Images } from 'utils/images';
import { Breakpoints } from 'theme/breakpoints';
import { useMedia } from 'react-media';

interface Props {
  data?: {
    title?: string;
    button?: string;
  }
}

export const MainSection: FC<Props> = ({ data = {} }) => {
  const { t } = useTranslation();
  const { button } = data;
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
  const [successDialogIsOpen, setSuccessDialogIsOpen] = useState<boolean>(false);
  const [unsuccessDialogIsOpen, setUnsuccessDialogIsOpen] = useState<boolean>(false);

  const isMobile = useMedia({ query: `(max-width: ${Breakpoints.mobile})` });

  const successMessages = {
    title: t('subscribeDialogSuccessedTitle'),
    description: t('subscribeDialogSuccessedDescription'),
    button: t('subscribeDialogSuccessedButton')
  };

  const unsuccessMessages = {
    title: t('subscribeDialogUnsuccessedTitle'),
    description: t('subscribeDialogUnsuccessedDescription'),
    button: t('subscribeDialogUnsuccessedButton')
  };

  return (
    <>
      <SubscriptionDialog
        onClose={() => setDialogIsOpen(false)}
        dialogIsOpen={dialogIsOpen}
        showSuccessNotify={() => setSuccessDialogIsOpen(true)}
        showUnsuccessNotify={() => setUnsuccessDialogIsOpen(true)}
      />
      <NotifyDialog
        onClose={() => setUnsuccessDialogIsOpen(false)}
        isOpen={unsuccessDialogIsOpen}
        title={unsuccessMessages.title}
        description={unsuccessMessages.description}
        button={unsuccessMessages.button}
      />
      <NotifyDialog
        onClose={() => setSuccessDialogIsOpen(false)}
        isOpen={successDialogIsOpen}
        title={successMessages.title}
        description={successMessages.description}
        button={successMessages.button}
      />
      <Wrapper>
        {
          isMobile
            ? null
            : <Background src={Images.mainPageBackground} alt="background" />
        }
        {
          button
            ?
              isMobile
                ? <MobileButton onClick={() => setDialogIsOpen(true)}>
                    <MobileButtonIcon src={Images.emailIcon} alt="email-icon" />
                  </MobileButton>
                :  <ButtonContainer><Button size="large" onClick={setDialogIsOpen}>{button}</Button></ButtonContainer>
            : null
        }
      </Wrapper>
    </>
  );
};

MainSection.defaultProps = {
  data: {
    title: '',
    button: ''
  }
};
