import styled, { css } from 'styled-components';
import { Colors } from 'theme/colors';
import { Breakpoints } from 'theme/breakpoints';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  transform: translateX(-100vw);
  animation: slideout 0.5s backwards;

  ${({ isOpen }: Props) => isOpen && css`
    animation: slide 0.5s forwards;
  `};

  @keyframes slide {
    100% {
    transform: translateX(0);
    background: rgba(19,38,92,.34);
}
  }
  @keyframes slideout {
  0% {transform: translateX(0)}
    100% {transform: translateX(-100vw)}
  }
`;

type Props = {
  isOpen: boolean
}


export const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  max-width: 516px;
  height: 100%;
  margin-right: auto;
  padding: 20px 20px 32px 20px;
  background: ${Colors.background.white};
  overflow-y: auto;

  @media screen and (max-width: ${Breakpoints.mobile}) {
    padding: 10px 24px 20px 10px;
    max-width: 280px;
  }
`;

export const Top = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
  @media screen and (max-width: ${Breakpoints.mobile}) {
    margin-bottom: 20px;
  }
`;

export const CloseButton = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
`;

export const CLoseButtonLines = styled.div`
  transform: rotate(135deg);
  display: block;
  top: 50%;
  width: 24px;
  height: 2px;
  background-color: ${Colors.background.blue};
  border-radius: 4px;
  position: absolute;
  :after {
    content: "";
    display: block;
    width: 24px;
    height: 2px;
    background-color: ${Colors.background.blue};
    border-radius: 4px;
    position: absolute;
    bottom: 0;
    transform: rotate(-90deg);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 24px;
`;

export const LinkItem = styled.div`
  font-size: 16px;
  font-weight: 600;

  a {
    color: ${Colors.text.black};
    transition: color ease .25s;

    :hover {
       color: ${Colors.text.lightBlue};
    }
  }

  & + & {
  margin-top: 16px;
  }
`;

export const RouterLink = styled(Link)``;

export const StyledLink = styled.a`
  color: ${Colors.text.black};
  font-weight: 600;
  & + & {
    margin-top: 20px;
  }
`;

export const Bottom = styled.div`
   text-align: center;
   display: block;
   margin-top: auto;
   button {
    width: 100%;
   }
`;
