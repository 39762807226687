import styled from "styled-components";
import { Colors } from 'theme/colors';
import { ButtonText } from "theme/Thypography";

export const Link = styled.a`
  display: flex;
  align-items: center;
  color: ${Colors.background.darkIcon};
  transition: opacity ease .25s;

  &:hover {
    opacity: 0.9;
  }
  & + & {
    margin-top: 16px;
  }
`;

export const Icon = styled.img`
  width: 28px;
  height: 28px;
`;

export const Social = styled(ButtonText)`
  margin-left: 12px;
  font-weight: 800;

`;
