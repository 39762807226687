import React, { FC } from 'react';
import {
  List,
  Wrapper,
  Listitem,
  StyledLink,
  RouterLink
} from './styles';

export interface NavBarLink {
  id: number;
  text: string;
  path?:string;
  href?:string;
  action?:any;
}

export interface Props {
  links: NavBarLink[]
}

export const HeaderNavbar:FC<Props> = ({ links }) => {

  const linkRender = (link:NavBarLink):any => {
    if(link.href) {
      return <StyledLink onClick={link.action ? link.action : undefined} href={link.href}>{link.text}</StyledLink>;
    } else if (link.path) {
      return <RouterLink to={link.path}>{link.text}</RouterLink>;
    }
  };

  const renderLinks = (links:NavBarLink[]) => {
    return links.map(link => {
      return(
        <Listitem key={link.id}>
          {linkRender(link)}
        </Listitem>
      );
    });
  };

  return (
    <Wrapper>
      <List>
        {renderLinks(links)}
      </List>
    </Wrapper>
  );
};
