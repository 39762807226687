import React, { useEffect } from 'react';
import { useTranslation } from "utils/i18n";
import styled from 'styled-components';

import { ContactsDataSection } from 'components/Sections/ContactsDataSection';
import { ContactsFormSection } from 'components/Sections/ContactsFormSection';
import { sendGoogleAnalyticsMetrik } from "utils/GA";
import { MetaTags } from 'common/MetaTags';
import { Socials } from "../components/Components/ContactsDataContent/ContactsDataContent";

const Wrapper = styled.div`
  min-height: calc(100vh - 90px - 233px);
`;

const ContactsPage = () => {
  const baseUrl = 'https://datalize.org';
  const { t } = useTranslation();

  useEffect(() => {
    sendGoogleAnalyticsMetrik('open_contacts_page', 'open_contacts_page');
  },[]);

  const seoSettings = {
    title: t('metaTitleContactsPage'),
    metaDescription: t('metaDescriptionContactsPage'),
    metaKeywords: t('metaCommonKeywords'),
    opGraphTitle: t('metaOrGraphTitleMainPage'),
    opGraphDescription: t('metaOrGraphDescriptionMainPage'),
    opGraphSite: t('metaSiteMainPage'),
    canonical: `${baseUrl}${t('metaCanoncialContactsPage')}`,
    alternateRu: `${baseUrl}${t('metaAlternateRuContactsPage')}`,
    alternateEn: `${baseUrl}${t('metaAlternateEnContactsPage')}`,
    alternateDefault: `${baseUrl}${t('metaAlternateEnContactsPage')}`,
    baseUrl
  };

  const socials: Socials = {
    label: t('contactsSocialsTitle'),
    items: [
      {
        icon: 'facebookDarkIcon',
        link: 'https://www.facebook.com/datalizeSL',
        social: 'Facebook'
      },
      {
        icon: 'instagramDarkIcon',
        link: 'https://www.instagram.com/datalizesl/',
        social: 'Instagram'
      },
      {
        icon: 'linkedinDarkIcon',
        link: 'https://www.linkedin.com/company/datalize-sl',
        social: 'Linkedin'
      },
      {
        icon: 'youtubeDarkIcon',
        link: 'https://www.youtube.com/@datalize',
        social: 'Youtube'
      },
      {
        icon: 'tiktokDarkIcon',
        link: 'https://www.tiktok.com/@datalizecompany',
        social: 'TikTok'
      }
    ]
  };

  const emails = {
    label: t('contactsEmailsTitle'),
    items: ['sales@datalize.org']
  };

  const telegrams = {
    label: t('contactsPhonesTitle'),
    links: ['https://t.me/alexeylukyanin']
  };

  return (
    <>
      <MetaTags settings={seoSettings} />
      <Wrapper>
        <ContactsDataSection socials={socials} emails={emails} telegrams={telegrams} />
        <ContactsFormSection />
      </Wrapper>
    </>
  );
};

export default ContactsPage;
