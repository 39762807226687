import styled from 'styled-components';
import { TypographySubtitle, TypographyText, TypographyTitle } from "theme/Thypography";
import { Colors } from "theme/colors";
import { Breakpoints } from 'theme/breakpoints';

export const Wrapper = styled.div`
  flex: 0 0 45%;
  max-width: 45%;
  padding: 10px;

  @media screen and (max-width:${Breakpoints.tablet}) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
    text-align: center;
  }
`;

export const Title = styled(TypographyTitle)`
  color: ${Colors.text.black};
`;

export const Description = styled(TypographySubtitle)`
  margin-top: 24px;
  color: ${Colors.text.darkGrey};
  @media screen and (max-width:${Breakpoints.tablet}) {
    margin-top: 6px;
  }
`;

export const Text = styled(TypographyText)`
  margin-top: 12px;
  color: ${Colors.text.grey};
  @media screen and (max-width:${Breakpoints.tablet}) {
    margin-top: 6px;
  }
`;
