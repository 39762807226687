import React, { FC } from 'react';
import { Wrapper } from './styles';

import { Header } from 'components/Sections/Header';
import { Footer }  from 'components/Sections/Footer';

interface Props {
  children?: any;
}

export const LandingLayout: FC<Props> = ({ children }) => {
  return (
    <Wrapper>
      <Header />
      <main>{children}</main>
      <Footer />
    </Wrapper>
  );
};
