import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from "utils/i18n";

interface Settings {
  title: string,
  metaDescription?:string,
  metaKeywords?:string,
  opGraphTitle?:string,
  opGraphDescription?:string,
  opGraphSite?:string,
  opGraphImageTwitter?:string,
  opGraphMainImage?:string,
  canonical?: string,
  alternateEn?: string,
  alternateRu?: string,
  alternateDefault?: string,
  baseUrl?:string
}

interface Props {
  settings: Settings
}

export const MetaTags: FC<Props> = ({ settings }) => {
  const { i18n } = useTranslation();

  return (
    <Helmet
      htmlAttributes={{ lang: i18n.language }}
      title={settings.title}
      meta={[
        { name: 'description', content: settings.metaDescription },
        { name: 'keywords', content: settings.metaKeywords },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:title', content: settings.opGraphTitle },
        { name: 'twitter:description', content: settings.opGraphDescription },
        { name: 'twitter:site', content: settings.opGraphSite },
        { name: 'twitter:image:src', content: settings.opGraphImageTwitter },
        { property: 'og:type', content: 'website' },
        { property: 'og:locale', content: 'en_US' },
        { property: 'og:url', content: settings.canonical },
        { property: 'og:site_name', content: 'Datalize' },
        { property: 'og:image', content: settings.opGraphMainImage || 'content="%PUBLIC_URL%/favicon-96x96.png"' },
        { property: 'og:image:secure_url', content: settings.opGraphMainImage },
        { property: 'og:image:type', content: 'image/png' },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '630' },
        { property: 'og:title', content: settings.opGraphTitle },
        { property: 'og:description', content: settings.opGraphDescription },
        { name: 'image', content: settings.opGraphMainImage }
      ]}
      link={[
        { rel: 'canonical', href: settings.canonical },
        { rel: 'alternate', href: settings.alternateRu, hrefLang: 'ru' },
        { rel: 'alternate', href: settings.alternateEn, hrefLang: 'en' },
        { rel: 'alternate', href: settings.alternateDefault, hrefLang: 'x-default' }
      ]}
    />
    );
};
