import React from 'react';

import {
  StyledHeader,
  Wrapper
} from './styles';

import { HeaderContent } from 'components/Components/HeaderContent';
import { useTranslation } from "utils/i18n";
import { useHistory } from "react-router-dom";

export const Header = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const formedLinkByLanguage = (link:string):string => {
    return i18n.language === 'ru' ? `/ru${link}` : link;
  };

  const scrollToSection = (section:any):void => {
    const headerHeight = 90;
    window.scrollTo({
      top: section.offsetTop - headerHeight,
      behavior: 'smooth'
    });
  };

  const scrollToSectionById = (id:string):void => {
    const section = document.getElementById(id);
    if (section && section.offsetTop) {
      scrollToSection(section);
    } else {
      history.push(formedLinkByLanguage('/'));
      setTimeout(() => {
        const repeatSection =  document.getElementById(id);
        if(repeatSection && repeatSection.offsetTop) {
          scrollToSection(repeatSection);
        }
      }, 500);

    }
  };

  const navBarLinks = [
    {
      id: 1,
      text: t('headerNavBarLink1'),
      path: formedLinkByLanguage('/')
    },
    {
      id: 2,
      text: t('headerNavBarLink2'),
      href: '#',
      action: (event:any):void => {
        event.preventDefault();
        scrollToSectionById('about');
      }
    },
    {
      id: 3,
      text: t('headerNavBarLink3'),
      href: '#',
      action: (event:any):void => {
        event.preventDefault();
        scrollToSectionById('portfolio');
      }
    },
    {
      id: 4,
      text: t('headerNavBarLink4'),
      href: '#',
      action: (event:any):void => {
        event.preventDefault();
        scrollToSectionById('technologies');
      }
    },
    {
      id: 5,
      text: t('headerNavBarLink5'),
      href: '#',
      action: (event:any):void => {
        event.preventDefault();
        scrollToSectionById('clients');
      }
    },
    {
      id: 6,
      text: t('headerNavBarLink6'),
      href: '#',
      action: (event:any):void => {
        event.preventDefault();
        scrollToSectionById('projects');
      }
    },
    {
      id: 7,
      text: t('headerNavBarLink7'),
      href: '#',
      action: (event:any):void => {
        event.preventDefault();
        scrollToSectionById('services');
      }
    },
  ];
  const logoText = t('headerLogoText');

  return (
    <StyledHeader>
      <Wrapper>
        <HeaderContent
          links={navBarLinks}
          logoText={logoText}
        />
      </Wrapper>
    </StyledHeader>
  );
};
