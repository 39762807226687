import React, { FC } from 'react';
import Slider from 'react-slick';
import { Wrapper, Dot } from './styles';
import { OurProject } from 'components/Components/OurProjectsContent/OurProject';

import { ProjectType } from 'components/Components/OurProjectsContent/OurProject/OurProject';

interface Props {
  projects: ProjectType[]
}

export const OurProjectCarousel:FC<Props> = ({ projects }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    autoplaySpeed: 6000,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    focusOnSelect:true,
    arrows: false,
    className: 'projects-slider',
    customPaging: () => (<Dot />),
    responsive: [
      {
        breakpoint: 1120,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 740,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };

  return (
    <Wrapper>
        <Slider {...settings}>
          {
            projects.map((project, index) => (
              <OurProject key={index} project={project} />
            ))
          }
        </Slider>
    </Wrapper>
  );
};

OurProjectCarousel.defaultProps = {
  projects: []
};
