import styled from 'styled-components';
import { Colors } from 'theme/colors';
import { TypographyTitle } from "theme/Thypography";
import { Breakpoints } from 'theme/breakpoints';

export const Wrapper = styled.div`
  padding: 120px 0 125px 0;
  background-color: ${Colors.background.darkWhite};

  @media screen and (max-width: ${Breakpoints.tablet}) {
    padding: 60px 0 100px 0;
  }
`;

export const Title = styled(TypographyTitle)`
  color: ${Colors.text.black};
  @media screen and (max-width: ${Breakpoints.tablet}) {
    text-align: center;
  }
`;
