import React from 'react';
import { Wrapper } from './styles';
import { MainContainer } from 'wrappers/MainContainer';
import { FooterContent } from 'components/Components/FooterContent';
import { SocialItemType } from "../../Components/FooterContent/FooterLeftBlock/SocialsBlock/SocialsBlock";

export const Footer = () => {
  const socials:SocialItemType[] = [
    {
      icon: 'instagram',
      href: 'https://www.instagram.com/datalizesl/'
    },
    {
      icon: 'facebook',
      href: 'https://www.facebook.com/datalizeSL'
    },
    {
      icon: 'linkedin',
      href: 'https://www.linkedin.com/company/datalize-sl'
    },
    {
      icon: 'youtube',
      href: 'https://www.youtube.com/@datalize',
      customStyle: {
        width: '44px',
        height: '44px'
      }
    },
    {
      icon: 'tiktok',
      href: 'https://www.tiktok.com/@datalizecompany',
      customStyle: {
        width: '32px',
        height: '40px'
      }
    }
  ];

  const telegrams = ['https://t.me/alexeylukyanin'];

  return (
    <Wrapper>
      <MainContainer>
        <FooterContent socials={socials} telegrams={telegrams} />
      </MainContainer>
    </Wrapper>
  );
};
