import React, { FC, useState } from 'react';
import { Wrapper, CloseButton, Title, Form } from './styles';
import { Button } from 'common/Button';
import { Input } from 'common/Input';
import Modal from 'react-modal';
import { useTranslation } from "utils/i18n";
import { Formik } from 'formik';
import SubscribeFormSchema from 'utils/validation-schema/SubscribeDialogForm';
import './styles.css';
import { sendGoogleAnalyticsMetrik } from 'utils/GA';

Modal.setAppElement('#modal-root');

interface SubscribeFormValues {
  email: string;
}

interface Props {
  onClose?: any,
  showSuccessNotify?: any,
  showUnsuccessNotify?: any,
  dialogIsOpen: boolean
}

export const SubscriptionDialog: FC<Props> = ({ dialogIsOpen, onClose, showSuccessNotify, showUnsuccessNotify }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const validationMessages = {
    emailInvalid: t('validationEmailIsInvalid'),
    emailRequired: t('validationEmailRequired')
  };

  const initialFormValue: SubscribeFormValues = { email: '' };

  const sendToTelegram = async (values:any) => {
    const { email } = values;
    const alarmerBotUrl = 'https://alarmerbot.ru';
    const alarmerApiKey = process.env.REACT_APP_ALARMER_API_KEY || '52ec8a-4767ed-4c02da';
    const message = `${t('telegramSubscribeFormName')}, ${email} ${t('telegramSubscribeFormAction')}`;

    const dataToAnalytics = {
      'user_data': `Email: ${email}`
    };

    try {
      await fetch(`${alarmerBotUrl}?key=${alarmerApiKey}&message=${message}`, {
        method: 'GET',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      });
      sendGoogleAnalyticsMetrik('send_subscribe_form', 'subscribe_form', dataToAnalytics);
    } catch (error) {
      throw error;
    }
  };

  const sendToEmail = async (values:any) => {
    const { email } = values;
    const formSubmitUrl = 'https://formspree.io/f/';
    const emailId = 'mknpzrqk';
    const urlForRequest = `${formSubmitUrl}${emailId}`;
    const message = `${email} ${t('telegramSubscribeFormAction')}`;
    const dataToEmail = {
      message,
      email,
      name: 'Форма - Связаться с нами'
    };
    try {
      await fetch(urlForRequest, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(dataToEmail)
      });
    } catch (error) {
      throw error;
    }
  };

  const submit = async (values: any, actions: any) => {
    try {
      setLoading(true);
      await sendToTelegram(values);
      await sendToEmail(values);
      actions.resetForm();
      onClose();
      showSuccessNotify();
    } catch (error) {
      console.error(error);
      showUnsuccessNotify();
    } finally {
      setLoading(false);
      actions.resetForm();
      onClose();
    }
  };

  return (
    <Modal
      className="Modal"
      overlayClassName="Overlay"
      isOpen={dialogIsOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
    >
      <Wrapper>
        <CloseButton type="button" onClick={onClose} />
        <Title>{t('subscribeDialogTitle')}</Title>
        <Formik
          initialValues={initialFormValue}
          validationSchema={SubscribeFormSchema(validationMessages)}
          onSubmit={(values, actions) => submit(values, actions)}
        >
          {
            (props) => (
              <Form onSubmit={props.handleSubmit}>
                <Input
                  form={props}
                  name="email"
                  placeholder={t('subscribeDialogEmailPlaceholder')}
                />
                <Button
                  submitBtn
                  size="large"
                  disabled={loading}
                  text={t('subscribeDialogSubscribe')}
                />
              </Form>
            )
          }
        </Formik>

      </Wrapper>
    </Modal>
  );
};
