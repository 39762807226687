import React, { FC } from 'react';
import { Wrapper, Link, Icon } from './styles';
import { CSSProperties } from "styled-components";

export interface SocialItemType {
  icon: string,
  href: string
  customStyle?: CSSProperties
}

interface Props {
  socials: SocialItemType[]
}

export const SocialsBlock:FC<Props> = ({ socials }) => {
  return (
    <Wrapper>
      {
        socials.map(social => (
          <Link key={social.icon} target="_blank" href={social.href} style={social.customStyle}>
            <Icon background={`${social.icon}Icon`} />
          </Link>
        ))
      }
    </Wrapper>
  );
};

SocialsBlock.defaultProps = {
  socials: []
};
