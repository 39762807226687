const ruTranslation = {
  // currentValue: "Current value is {{value}}",
  // HEADER
  headerNavBarLink1: "Главная",
  headerNavBarLink2: "О нас",
  headerNavBarLink3: "Продукты",
  headerNavBarLink4: "Технологии",
  headerNavBarLink5: "Клиенты",
  headerNavBarLink6: "Проекты",
  headerNavBarLink7: "Прочие услуги",
  headerLogoText: "Datalize",
  headerLanguage: "Eng",
  // MAIN SECTION TEXT
  mainSectionTitle: 'Анализ данных для Вашего бизнеса',
  mainSectionDescription: 'Houzez is an innovative real estate WordPress theme that helps to ensure your website’s success in this super-competitive market.',
  mainSectionButtonText: 'Контакты',
  mainSectionContactsWithUs: 'Связаться с нами',
  // ABOUT US SECTION
  aboutUsTitle: 'О Нас',
  aboutUsText: 'Компания Datalize предоставляет полный спектр услуг по разработке программного обеспечения, его поддержке и сопровождению. Мы оказываем аутсорсинговые услуги, внедряем Data Science решения, интеллектуализируем и автоматизируем бизнес-процессы, проводим исследования статистических данных, ориентированных на извлечение стратегически важных зависимостей и закономерностей для Вашего бизнеса.',
  // PORTFOLIO
  portfolioSubtitle: "Welcome to Houzez 2.0",
  portfolioTitle: "Услуги",
  portfolioPoint1: "Cбор и хранение данных",
  portfolioPoint2: "Анализ бизнес-процессов",
  portfolioPoint3: "Разработка систем визуализации данных",
  portfolioPoint4: "Разработка систем прогнозирования",
  portfolioPoint5: " Разработка рекомендательных систем",
  portfolioPoint6: "Разработка интеллектуальных мессенджеров",
  portfolioLinkText: "Посмотреть полностью",
  portfoliodData1Subtitle: "сбор и хранение данных",
  portfoliodData1Title: "Разработка систем по сбору и хранению данных",
  portfoliodData1Text: "<p>Разработка автоматизированных систем сбора и хранения любых данных.</p><ul><li> определение структуры базы данных</li><li>подбор наиболее оптимальной технологии для Вашего бизнеса </li><li>разворачивание базы данных на сервере</li><li>настройка автоматизированного сбора данных</li><li>предоставление программного интерфейса для удобного взаимодействия с базой данных</li><li>разработка интерфейса интерактивного графического анализа данных</li></ul><p>Своевременный сбор данных позволит повысить эффективность Вашего бизнеса и вести глубокую аналитику в будущем</p> ",
  portfoliodData2Subtitle: "анализ бизнес-процессов",
  portfoliodData2Title: "Описание и разработка бизнес-процессов",
  portfoliodData2Text: "<p>Разработка и формализация бизнес-процессов с целью их оптимизации позволяет глубоко проанализировать эффективность отдельных этапов работы и их совокупностей. Анализ и описание бизнес-процессов позволяет выстроить логическую цепочку действий для каждого из процессов и в результате получить четко функционирующую систему.</p><p>Принятие решений, основанных на анализе бизнес-процессов позволит выявить их слабые и сильные стороны, что повысит общую эффективность бизнеса.</p>",
  portfoliodData3Subtitle: "визуализация   данных",
  portfoliodData3Title: "Разработка систем визуализации данных",
  portfoliodData3Text: "<p>Используя системы визуализации статистических данных, Вы всегда будете знать изменение необходимых показателей бизнеса, таких как продажи, выручка, складские запасы и прочее. Отслеживайте ключевые показатели, анализируйте тенденции, наблюдайте за результатами деятельности благодаря качественной визуализации данных.</p><p>Визуальный анализ — 80% полезной информации Ваших данных, графическое представление позволит принимать деловые решения максимально оперативно.</p>",
  portfoliodData4Subtitle: "прогнозирование",
  portfoliodData4Title: "Разработка систем прогнозирования",
  portfoliodData4Text: "<p>Прогнозирование предполагает оценку статистически наиболее вероятного исхода событий. Прогнозирование может затрагивать следующие направления:</p><ul><li>прогнозирование показателей на основе других факторов</li><li>решение задач классификации</li><li>прогнозирование на основе нейронных сетей</li><li>разработка автоматизированных систем прогнозирования для дальнейшего внедрения в бизнес процессы</li></ul><p>Оценка будущих тенденций позволит заблаговременно адаптироваться к ним.</p>",
  portfoliodData5Subtitle: "рекомендательные системы",
  portfoliodData5Title: "Разработка рекомендательных систем",
  portfoliodData5Text: "<p>Рекомендательная система может позволить повысить эффективность продаж на Вашем сайте, помогая предлагать клиентам только нужные товары и услуги. </p> <p>Современная рекомендательная система позволяет увеличить конверсию и продажи, а также повысить удобство пользования веб-сайтом или приложением.</p>",
  portfoliodData6Subtitle: "Чат-боты",
  portfoliodData6Title: "Разработка интеллектуальных мессенджеров",
  portfoliodData6Text: "<p>Создайте своего помощника в общении с клиентами. Таргетируйте продукты через чат-бот, сделайте интерактивным и автоматизированным взаимодействие клиента с Вашим магазином.</p><p>Чат-бот — это не только приложение для общения, но и удобный ресурс для продвижения и увеличения продаж Ваших товаров и услуг.</p>",

  portfoliodDataNew: "New",
  // SERVICES SECTION
  servicesTitle: "Прочие услуги",
  // ACCORDION DATA
  accordionTitleOne: "Сканнер цен",
  accordionDescriptionOne1: "Инструмент, позволяющий отслеживать рыночные цены товаров и услуг, предлагаемых конкурентами. Price Scanner визуализирует динамику рыночных цен интересующих Вас товаров и услуг в разрезе продавцов, а также предлагает различную статистику: анализ цен, динамики цен производителей, предоставленных скидок, разброса цен и множества других показателей.",
  accordionDescriptionOne2: "С помощью Price Scanner Вы сможете оптимизировать ценовую политику исходя из актуальных рыночных цен и действий конкурентов, разработать эффективную систему скидок и всегда иметь понимание сложившейся рыночной конъюнктуры.",
  accordionTitleTwo: "Гибкие скидки",
  accordionDescriptionTwo1: "Автоматизированная система скидок, рассчитываемая исходя из текущей корзины покупок клиента и необходимого уровня рентабельности продаж.",
  accordionDescriptionTwo2: "Позволяет автоматически определять уровень скидок для каждого отдельного клиента в зависимости от таких показателей как прибыль, объем покупок, рентабельность текущей корзины товаров и др.",
  accordionDescriptionTwo3: "Такой подход превратит Ваш магазин в интерактивное приложение, что всегда нравится клиентам и покупателям.",
  accordionTitleThree: "Анализ продаж",
  accordionDescriptionThree1: "Система анализа и визуализации данных о продажах в реальном времени. Основная функция – анализ и прогнозирование объема продаж товаров и услуг в торговых объектах или интернет-магазинах.",
  accordionDescriptionThree2: "Система даст возможность определить, какое количество товаров необходимо иметь на каждом торговом объекте исходя из сложившейся ситуации на рынке, выявит наиболее популярные и выгодные товарные группы для каждого объекта, оптимизирует управление складскими запасами, спрогнозирует потенциальный уровень выручки.",
  accordionDescriptionThree3: "Визуализация объемов продаж наиболее популярных товарных позиций, а также динамики цен по торговым объектам позволит повысить эффективность бизнеса.",
  accordionTitleFour: "Автоматизация торговых алгоритмов",
  accordionDescriptionFour1: "Мы предлагаем разработку автоматизированных систем торговли на различных криптовалютных биржах с использованием требуемых инструментов риск-менеджмента.",
  accordionDescriptionFour2: "Проведите тестирование своей стратегии, ищите пути ее оптимизации и запускайте на удаленном сервере, чтобы извлекать из рынка прибыль по вашей стратегии 24/7.",
  accordionTitleFive: "Анализ эффективности рекламы",
  accordionDescriptionFive1: "Проведение анализа эффективности используемой рекламы на различных площадках. Это позволит определить наиболее эффективные способы рекламы, таргетирования, привлечения клиентов и аудиторию с наибольшей покупательной способностью, а также выявить, насколько результативны для бизнеса изменения на сайте, торговом объекте, в подходе менеджеров к клиентам и других аспектах ведения бизнеса.",
  accordionTitleSix: "Анализ рынка для стартапов",
  accordionDescriptionSix1: "Вы хотите открыть бизнес или расширить существующий, однако сомневаетесь в его прибыльности? Компания Datalize предлагает Вам услугу по проведению анализа рынка товаров и услуг для определения сложившегося уровня цен, выявления основных конкурентов, а также их сильных и слабых сторон, сравнения условий поставщиков, среднего уровня рентабельности продаж, самых перспективных способов рекламы, сезонности и наиболее эффективных периодов ведения бизнеса.",
  accordionTitleSeven: "Система сканирования клиентов",
  accordionDescriptionSeven1: "Мы предлагаем Вам инструмент, основанный на информации, полученной из камер видеонаблюдения торговых объектов, и позволяющий выявить: •общую проходимость по месяцам/дням/часам; •постоянных клиентов на основе сканирования лиц;•потенциальную корзину товаров/услуг постоянных клиентов; •эффективность маркетингового подразделения на основе визуализации данных о проходимости в зависимости от изменений в работе.",
  accordionTitleEight: "Автоматизированный голосовой консультант",
  accordionDescriptionEight1: "Один из популярнейших способов снижения нагрузки на Ваш call-центр. Поможет дифференцировать запросы клиентов по соответствующим менеджерам, ответить на некоторые вопросы клиентов автоматически, а также повысить общую эффективность работы отдела поддержки.",
  accordionTitleNine: "Система анализа поведения клиентов",
  accordionDescriptionNine1: "Система, позволяющая определить источники трафика на Вашем сайте, среднюю продолжительность нахождения на сайте, наиболее популярные страницы среди клиентов, особенности поведения покупателей. Система работает на основе разработки и настройки уникальных метрик, сбора и дальнейшего анализа данных о Вашем сайте. Анализ поведения покупателей на сайте позволяет адаптировать к их личным предпочтениям предлагаемые товары, скидки, а также динамически изменять UI сайта.",
  accordionTitleTen: "Пакет \"Бизнес с нуля\"",
  accordionDescriptionTen1: "Данный продукт представляет собой совокупность наших сервисов: анализ рынка товаров и услуг, определение потенциальной результативности бизнеса, разработка маркетинговой стратегии, веб-сайта или интернет-магазина с рекомендательной системой и автоматизированным механизмом скидок, а также последующим сопровождением и анализом эффективности бизнеса.",
  accordionSecondTitleOne: "Анализ рисков финансовых инструментов",
  accordionSecondDescriptionOne1: "Предлагаем автоматизированную систему оценки рыночных рисков финансовых активов на основе различных методологий, используемых ведущими финансовыми компаниями мира.",
  accordionSecondDescriptionOne2: "Настройте систему с учетом Ваших пожеланий, риск-чувствительности и требований стратегии.",
  accordionSecondTitleTwo: "Поиск, анализ и внедрение научных решений",
  accordionSecondDescriptionTwo1: "Зачастую на многие вопросы необходим научный ответ. Наша команда проанализирует важнейшие существующие научные разработки для ответа на Ваш вопрос, а также проведет собственное исследование с использованием математико-статистического аппарата в различных областях.",
  accordionSecondDescriptionTwo2: "Мы найдем наиболее оптимальные пути внедрения полученных результатов в Ваш бизнес.",
  accordionSecondTitleThree: "Чат-боты и умные помощники",
  accordionSecondDescriptionThree1: "Чат-боты и умные помощники помогут Вам оптимизировать процесс общения с клиентами и сделать его более интерактивным. Наиболее популярные мессенджеры, такие как telegram, whatsapp, facebook messenger, имеют возможность интеграции ботов. Все это делает ответы на задаваемые вопросы моментальными, позволяет интегрировать оплату, размещение заявок, заказов, подключив бота к системе управления заказами, используемой Вашей компанией.",
  accordionSecondTitleFour: "Базы знаний и нечеткий поиск",
  accordionSecondDescriptionFour1: "В Вашей компании есть часто задаваемые вопросы или какая-то иная обширная информация, в которой сложно найти ответ на задаваемый вопрос? При помощи статистических подходов мы поможем оптимизировать процесс поиска, внедряя в Ваш ресурс алгоритмы, которые значительно увеличат вероятность того, что пользователь найдет то, что он действительно ищет.",
  accordionSecondTitleFive: "Разработка REST-API для алгоритмов Data Science",
  accordionSecondDescriptionFive1: "Предлагаем разработку математико-статистических алгоритмов и моделей Machine Learning исходя из поставленной задачи с последующей настройкой REST-API, который позволяет интегрировать взаимодействие с алгоритмами в Ваши сервисы или веб-сайт.",
  accordionSecondTitleSix: "Выявление мошенничества",
  accordionSecondDescriptionSix1: "Если злоумышленник получает доступ к аккаунту пользователя, то, как правило, ведет себя отличным от владельца образом, что можно выявить и с высокой точностью предотвращать нежелательные действия. Анализ активности в динамике позволяет выявить изменения паттернов поведения и заблокировать аккаунт до подтверждения пользователя.",
  accordionSecondTitleSeven: "Прогнозирование поломки оборудования, превентивное обслуживание",
  accordionSecondDescriptionSeven1: "Если Вам необходима автоматизированная система управления рисками, связанная с отказом и износом оборудования, наша компания готова предоставить решение, которое позволит прогнозировать в режиме реального времени потенциальные отказы различного оборудования.",
  accordionSecondTitleEight: "Сбор информации",
  accordionSecondDescriptionEight1: "Мы предлагаем услуги по разработке автоматизированных систем сбора и обработки данных с интересующих Вас веб-сайтов с последующим их хранением в базе данных. К примеру, Вы хотите анализировать комментарии и отзывы пользователей, оценки продукта на маркетплейсах или любую другую информацию, которую можно получить только посредством сбора с сайта, тогда это решение для Вас.",
  accordionSecondTitleNine: "Автоматизация бизнес-планирования и ERP-системы",
  accordionSecondDescriptionNine1: "Мы предлагаем разработку и внедрение ERP-системы для Вашего бизнеса, а также осуществляем интеграцию существующих платформ с полным сопровождением и настройкой.",
  accordionSecondTitleTen: "Веб-разработка",
  accordionSecondDescriptionTen1: "Мы оказываем полный комплекс услуг по web-разработке от создания и согласования дизайна сайта до размещения его на сервере с последующей поддержкой. Наша компания поможет создать уникальный дизайн для Вашего сайта, в том числе с использованием современных и наиболее эффективных инструментов Web-разработки, интегрировать необходимые метрики для сбора данных и последующего анализа эффективности работы сайта, а также провести необходимые SEO-настройки для продвижения сайта в поисковых системах.",
  // TECHNOLOGIES SECTION
  technologiesTitle: 'Технологии',
  technologiesDescription: 'Инструменты, используемые в работе',
  technologiesText: 'Мы используем наиболее актуальные инструменты разработки автоматизированных систем анализа данных. Наши сотрудники всегда в курсе последних событий и тенденций развития индустрии. Мы подберем наиболее оптимальный набор технологий, эффективно и в то же время экономно решающий Ваши задачи.',
  // OUR CLIENTS
  ourClientsTitle: 'Клиенты',
  ourClientsText: 'Мы открыты к международному сотрудничеству, в любой сфере бизнеса, что предоставляет нам уникальный опыт создания полнофункциональные решений с учетом характеристик и потребностей бизнеса наших клиентов.',
  // OUR PROJECTS
  ourProjectTitle: "Проекты",
  ourProjectOne: "Был проведен анализ, выявляющий причины оттока клиентов и сокращения подписок на новостную ленту, также была произведена оценка благосостояния базы клиентов, в зависимости от стоимостной оценки их недвижимости, что позволило создать систему прогнозирования поведения клиента. ",
  ourProjectTwo: "Был создан Telegram бот, копирующий основные функции приложений и сайта, также были имплементированы прогнозные модели, предугадывающие ответы пользователей на текущий вопрос, что внесло дополнительный интерактив, а также позволило предоставить больше рекомендаций, вне зависимости от числа ответов. ",
  ourProjectThree: "Была создана система тестирования финансовых стратегий, позволившая оптимизировать и отфильтровать различные параметры, используемые при торговле на мировых финансовых рынках.",
  ourProjectFour: "Анализ  возможности внедрения и разработка технического задания на основе результатов научных исследований, с целью создания уникальной собственной системы прогнозирования самочувствия человека в зависимости от погодных явлений и выдачи рекомендаций, для нивелирования негативных эффектов для здоровья.",
  // FOOTER
  footerAllRightsCompany: "© 2020 Datalize",
  footerAllRightsReserved: "Все права защищены",
  footerCountryAndCity: "Прага, Чехия",
  footerContactsCollaboration: "Сотрудничество",
  footerContactsPhone: "+ 375 29 123 33 17",
  footerContactsEmail: "sales@datalize.org",
  // SUBSCRIBE DIALOG
  subscribeDialogTitle: "Оставьте свои данные и мы свяжемся с вами",
  subscribeDialogEmailPlaceholder: "Введите email",
  subscribeDialogSubscribe: "Отправить",
  subscribeDialogSuccessedTitle: "Отправка формы прошла успешно",
  subscribeDialogSuccessedDescription: "Спасибо, скоро с Вами свяжется наш сотрудник.",
  subscribeDialogSuccessedButton: "Отлично!",
  subscribeDialogUnsuccessedTitle: "Ошибка при отправке формы",
  subscribeDialogUnsuccessedDescription: "Извините, попробуйте отправить форму чуть позже",
  subscribeDialogUnsuccessedButton: "Хорошо",
  // VALIDATIONS
  validationEmailIsInvalid: 'Введите правильный Email',
  validationEmailRequired: 'Email обязательное поле',
  validationNameRequired: 'Имя обязательное поле',
  validationLastNameRequired: 'Фамилия обязательное поле',
  validationPhoneRequired: 'Телефон обязательное поле',
  // TELEGRAMS MESSAGE
  telegramSubscribeFormName: 'Форма (СВЯЗАТЬСЯ С ВАМИ)',
  telegramSubscribeFormAction: 'просит связаться с ним',
  telegramContactsFormName: 'Форма (Страница Контакты)',
  ////////////////
  // CONTACTS PAGE
  ////////////////
  contactsTitle: "Наши <br/> контакты",
  contactsMainTitle: "СВЯЗАТЬСЯ С НАМИ",
  contactsFormNamePlaceholder: "Имя",
  contactsFormLastNamePlaceholder: "Фамилия",
  contactsFormPhonePlaceholder: "Телефон",
  contactsFormEmailPlaceholder: "Email",
  contactsFormMessagePlaceholder: "Сообщение",
  contactsFormSubmitButton: "Отправить",
  contactsEmailsTitle: 'Электронная почта',
  contactsEmailsGeneralLabel: 'Общие',
  contactsEmailsCooperationLabel: 'Сотрудничество',
  contactsEmailsTechnicalLabel: 'Технические',
  contactsSocialsTitle: 'Социальные сети',
  contactsPhonesTitle: 'Telegram',
  // SEO BLOCK
  metaTitleMainPage: 'Аналитика данных для вашего бизнеса - Datalize',
  metaTitleContactsPage: 'Контакты - Datalize',
  metaDescriptionMainPage: 'Компания Datalize предоставляет полный спектр услуг по разработке программного обеспечения, его поддержке и сопровождению. Мы оказываем аутсорсинговые услуги, внедряем Data Science решения, интеллектуализируем и автоматизируем бизнес-процессы, проводим исследования статистических данных, ориентированных на извлечение стратегически важных зависимостей и закономерностей для Вашего бизнеса.',
  metaDescriptionContactsPage: 'Мы найдём ответы на ваши вопросы, а также проконсультируем, если Вы не уверены в том, с чего начать. Отправляйте вопросы через нашу форму или задайте напрямую. А также подписывайтесь на наши соцсети – будьте в курсе новостей. Мы всегда на связи.',
  metaCommonKeywords: '',
  metaOrGraphTitleMainPage: 'Аналитика данных для вашего бизнеса - Datalize',
  metaOrGraphDescriptionMainPage: 'Компания Datalize предоставляет полный спектр услуг по разработке программного обеспечения, его поддержке и сопровождению. Мы оказываем аутсорсинговые услуги, внедряем Data Science решения, интеллектуализируем и автоматизируем бизнес-процессы, проводим исследования статистических данных, ориентированных на извлечение стратегически важных зависимостей и закономерностей для Вашего бизнеса.',
  metaSiteMainPage: '@datalize',
  metaCanoncialMainPage: '/ru/',
  metaAlternateRuMainPage: '/ru/',
  metaAlternateEnMainPage: '',
  metaCanoncialContactsPage: '/ru/contacts/',
  metaAlternateRuContactsPage: '/ru/contacts/',
  metaAlternateEnContactsPage: '/contacts/',
};

export default ruTranslation;
