import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import MainRouter from "router";
import { GlobalStyle } from "./theme";

import './theme/react-slick/styles.css';
import './theme/react-slick/theme-styles.css';

const App: React.FC = () => {
  return (
    <BrowserRouter>
        <Switch>
          <Route path="/ru/">
            <MainRouter lng="ru" />
          </Route>

          <Route path="/">
            <MainRouter />
          </Route>
        </Switch>
      <GlobalStyle />
    </BrowserRouter>
  );
};

export default App;
