import React, { FC } from 'react';
import {
  Wrapper,
  Subtitle,
  Title,
  PointsContainer,
  PointItem,
  PointIcon,
  PointText
} from './styles';
import { Images } from 'utils/images';

export interface HeaderDataType {
  title?: string;
  subtitle?: string;
  points: string[]
}

interface Props {
  header: HeaderDataType,
  showPoints: boolean
}

export const PortfolioHeader:FC<Props> = ({ header, showPoints }) => {
  return (
    <Wrapper>
      <Subtitle>
        {header.subtitle}
      </Subtitle>
      <Title>
        {header.title}
      </Title>
      {
        showPoints
          ? <PointsContainer>
              {
                header.points.map((item, index) => {
                  return(
                    <PointItem key={index}>
                      <PointIcon>
                        <img src={Images.checkIcon} alt="check" />
                      </PointIcon>
                      <PointText key={index}>{item}</PointText>
                    </PointItem>
                  );
                })
              }
            </PointsContainer>
          : null
      }
    </Wrapper>
  );
};

PortfolioHeader.defaultProps = {
  header: {} as HeaderDataType,
  showPoints: false
};
