import styled from 'styled-components';
import { TypographyText, TypographyTitle } from "theme/Thypography";
import { Colors } from 'theme/colors';
import { Breakpoints } from 'theme/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${Breakpoints.tablet}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Title = styled(TypographyTitle)`
  color: ${Colors.text.black};
`;

export const Text = styled(TypographyText)`
  text-align: justify;
  max-width: 60%;
  color: ${Colors.text.darkGrey};

  @media screen and (max-width: ${Breakpoints.tablet}) {
    max-width: 100%;
    text-align: center;
  }
`;
