import React, { FC, useState } from 'react';
import { Wrapper, LogoContainer, ActionContainer, InvisibleLink, InvisivleLinksBlock } from './styles';
import { HeaderBurger } from "components/Components/HeaderContent/HeaderBurger";
import { Logo } from "common/Logo";
import { HeaderNavbar } from "components/Components/HeaderContent/HeaderNavbar";
import { Button } from "common/Button";
import { HeaderMobileMenu } from "components/Components/HeaderContent/HeaderMobileMenu";
import { NavBarLink } from 'components/Components/HeaderContent/HeaderNavbar/HeaderNavbar';
import { HeaderLanguageButton } from 'components/Components/HeaderContent/HeaderLanguageButton';
import { useTranslation } from "utils/i18n";
import { useHistory, useLocation } from 'react-router';
import { Breakpoints } from 'theme/breakpoints';
import { useMedia } from 'react-media';

interface Props {
  links: NavBarLink[],
  logoText: string;
}

export const HeaderContent: FC<Props> = ({ links, logoText }) => {
  const [mobileMenuIsOpen, toggleMobileMenu] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const isTablet = useMedia({ query: `(max-width: ${Breakpoints.tablet})` });

  const changeLanguage = () => {
    const pathname = location.pathname;
    if(i18n && i18n.language === 'en') {
      history.push(`/ru${pathname}`);
    } else if (i18n && i18n.language === 'ru') {
      history.push(pathname.replace('/ru',''));
    }
  };

  const formedLinkByLanguage = (link:string):string => {
    return i18n.language === 'ru' ? `/ru${link}` : link;
  };

  return (
    <>
      <Wrapper>
        <HeaderBurger onClick={() => toggleMobileMenu(true)} />
        <LogoContainer>
          <Logo logoText={logoText} />
        </LogoContainer>
        <InvisivleLinksBlock>
          <InvisibleLink href="https://datalize.org/" />
          <InvisibleLink href="https://datalize.org/ru/" />
          <InvisibleLink href="https://datalize.org/ru/contacts" />
          <InvisibleLink href="https://datalize.org/contacts" />
        </InvisivleLinksBlock>
        <HeaderNavbar links={links} />
        <ActionContainer>
          <Button
            view="default"
            size="large"
            text={t('mainSectionButtonText')}
            type="link"
            to={formedLinkByLanguage('/contacts/')}
          />
          <HeaderLanguageButton onClick={changeLanguage} />
        </ActionContainer>
      </Wrapper>
      {
        isTablet
          ? <HeaderMobileMenu
              menu={links}
              onClick={() => toggleMobileMenu(false)}
              isOpen={mobileMenuIsOpen}
              changeLanguage={changeLanguage}
            />
          : null
      }
    </>
  );
};
