import styled from 'styled-components';
import { Breakpoints } from 'theme/breakpoints';

export const H1 = styled.h1`
  font-size: 45px;
  font-weight: 600;
  line-height: 1.3em;
  letter-spacing: -1px;
  font-family: 'Raleway', sans-serif;
`;

export const NavbarLi = styled.a`
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  line-height: 18px;
  font-family: '-apple-system','BlinkMacSystemFont','Segoe UI','Roboto','Helvetica Neue','Arial','Noto Sans',sans-serif;
`;

export const P1 = styled.p`
  font-size: 18px;
  line-height: 1.5em;
  font-weight: 300;
  font-family: 'Raleway', sans-serif;
  @media screen and (max-width: ${Breakpoints.mobile}) {
    font-size: 16px;
  }
`;

export const ButtonText = styled.span`
  font-size: 18px;
  font-weight: 500;
  font-family: 'Raleway', sans-serif;
  line-height: 1;
  @media screen and (max-width: ${Breakpoints.mobile}) {
    font-size: 16px;
  }
`;

export const TypographySubtitle = styled.h2`
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4em;
  font-family: 'Raleway', sans-serif;
`;

export const TypographyTitle = styled.h1`
  font-size: 40px;
  font-weight: 600;
  line-height: 1.3em;
  letter-spacing: -1px;
  font-family: 'Raleway', sans-serif;
  @media screen and (max-width: ${Breakpoints.mobile}) {
    font-size: 32px
  }
`;

export const TypographySmallerTitle = styled.h1`
  font-size: 30px;
  font-weight: 600;
  line-height: 1.3em;
  letter-spacing: -0.5px;
  font-family: 'Raleway', sans-serif;
  @media screen and (max-width: ${Breakpoints.mobile}) {
    font-size: 24px;
  }
`;

export const TypographyText = styled.div`
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5em;

  @media screen and (max-width: ${Breakpoints.mobile}) {
    font-size: 16px;
  }
`;

export const TypographySmallerText = styled.p`
  font-family: 'Raleway', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5em;
`;
