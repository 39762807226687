import React, { FC } from 'react';
import { Wrapper, Divider } from './styles';

import { OurClientsHeader } from 'components/Components/OurClientsContent/OurClientsHeader';
import { OurClientsMain } from 'components/Components/OurClientsContent/OurClientsMain';
import { Client } from "./OurClientsMain/OurClientsMain";

interface Props {
  header: {
    title: string,
    text: string
  },
  clients: Client[]
}

export const OurClientsContent:FC<Props> = ({ header, clients }) => {
  return (
    <Wrapper>
      <OurClientsHeader header={header} />
      <Divider />
      <OurClientsMain clients={clients} />
    </Wrapper>
  );
};

OurClientsContent.defaultProps = {
  header: {
    title: '',
    text: ''
  },
  clients: []
};
