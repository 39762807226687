import styled from 'styled-components';
import { Colors } from 'theme/colors';
import { TypographySmallerTitle } from 'theme/Thypography';
import { Breakpoints } from 'theme/breakpoints';

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  z-index: 80;
  background-color: #ffffff;
  padding: 40px 20px 30px 20px;
`;

export const CloseButton = styled.button`
  width: 32px;
  height: 32px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;

  &:hover {
   &:before, &:after {
      background-color: ${Colors.background.lightBlue};
    }
  }

  &:before, &:after {
  position: absolute;
  content: ' ';
  height: 33px;
  width: 3px;
  background-color: ${Colors.background.blue};
  transition: background-color ease .25s;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

export const Title = styled(TypographySmallerTitle)`
  margin-top: 12px;
  text-align: center;
  @media screen and (max-width: ${Breakpoints.mobile}) {
    font-size: 20px;
  }
`;

export const Form = styled.form`
  margin-top: 24px;
  display: flex;
  align-items: flex-start;
  overflow: auto;
  & > div:first-child {
    flex: 0 0 66.66666%;
    max-width: 66.66666%;
    input {
      width: 100%;
      border-radius: 8px 0 0 8px;
    }
  }
  button {
    margin-left: -8px;
    border-radius: 8px;
    width: 100%;
  }

  @media screen and (max-width: ${Breakpoints.tablet}) {
    flex-direction: column;
    & > div:first-child {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
      input {
        width: 100%;
        border-radius: 8px;
      }
    }
    button {
      margin-left: 0;
      margin-top: 12px;
    }
  }
`;
