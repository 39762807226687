import styled from 'styled-components';
import { Colors } from 'theme/colors';
import { Breakpoints } from 'theme/breakpoints';

export const Wrapper = styled.div`
  padding: 120px 0;
  background-color: ${Colors.background.darkWhite};

  @media screen and (max-width: ${Breakpoints.tablet}) {
    padding: 80px 0;
  }

  @media screen and (max-width: ${Breakpoints.tablet}) {
    padding: 40px 0;
  }
`;
