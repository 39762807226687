import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  html, body {
    height: 100%;
    width: 100%;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    background: #ffffff;
  }

  #root {
    height: 100%;
    width: 100%;
  }

  a {
    color: #6C00D8;
    text-decoration: none;
    outline: none;
  }
  button {
    outline: none;
  }

  b {
    font-weight: 500;
  }

  h1,h2,h3,h4,h5,h6,p {
    margin: 0;
    padding: 0;
  }

  ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 28px;
    }
  }

  @media (max-width: 575px) {
    h2 {
      font-size: 24px;
    }
  }
`;
