import React, { FC } from 'react';
import { Wrapper } from './styles';
import { TextBlock } from 'components/Components/TechnologiesContent/TextBlock';
import { IconsBlock } from 'components/Components/TechnologiesContent/IconsBlock';

interface Props {
  data: {
    title: string,
    description: string,
    text: string
  },
  icons: string[]
}

export const TechnologiesContent: FC<Props> = ({ data, icons }) => {
  const { title, description, text } = data;

  return (
    <Wrapper>
      <TextBlock
        title={title}
        description={description}
        text={text}
      />
      <IconsBlock icons={icons} />
    </Wrapper>
  );
};

TechnologiesContent.defaultProps = {
  data: {
    title: '',
    description: '',
    text: ''
  },
  icons: []
};
