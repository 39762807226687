import React, { FC } from 'react';
import { Container, StyledInput, StyledLabel, ErrorContainer, Error, ErrorLine } from './styles';

interface Props {
  label?: string;
  placeholder?: string;
  name: string;
  form?: any;
  disabled?:boolean;
  fullWidth?: string;
}

export const Input:FC<Props> = ({ label, name,placeholder, form, disabled,fullWidth, ...props }) => (
    <Container>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledInput
        fullwidth={fullWidth}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        {...props} />
        <ErrorContainer>
          {(form.errors[name] && form.touched[name]) && <Error><ErrorLine />{form.errors[name]}</Error>}
        </ErrorContainer>
    </Container>
  );

Input.defaultProps = {
  label: '',
  placeholder: '',
  name: '',
  disabled: false,
  form: {
    errors:{},
    touched: {}
  }
};
