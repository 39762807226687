import styled from 'styled-components';
import { Breakpoints } from 'theme/breakpoints';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: -10px;

  @media screen and (max-width: ${Breakpoints.tablet}) {
    flex-direction: column;
    margin: 0;
  }
`;
