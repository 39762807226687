import styled, { css } from 'styled-components';
import { Colors } from 'theme/colors';

interface Props {
  size?: "medium" | "small" | "large";
  view?: "default" | "bordered" | "unbordered" | "text";
  icon?: string;
}

const handleButtonSize = (size: string) => {
  switch (size) {
    case "medium":
      return css`
        padding: 11px 32px;
        font-size: 14px;
        line-height: 18px;
    `;
    case "large":
      return css`
        padding: 12px 24px;
        font-size: 18px;
        line-height: 22px;
        font-weight: 400;
     `;
    case "small":
      return css`
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
     `;
  }
};

const handleButtonView = (view: string) => {
  switch (view) {
    case "default":
      return css`
        background-color: ${Colors.background.blue};
        color: ${Colors.background.white};
        transition: all ease .3s;
        box-shadow: 0 2px 12px 0 rgba(13,33,57,1);

        &:hover {
          background-color: ${Colors.background.lightBlue};
          box-shadow: 0 2px 12px 0 rgba(25,50,95,1);
        };

        &:focus {
          background-color: ${Colors.background.lightBlue};
        };

        &:active {
          background-color: ${Colors.background.lightBlue};
        };

        &:disabled {
          background-color: ${Colors.background.lightGrey};
          box-shadow: none;
          cursor: default;
        };
      `;

    case "bordered":
      return css`
          border-width: 1px;
          border-style: solid;
          border-color: ${Colors.background.blue};
          background-color: transparent;
          color: ${Colors.background.blue};
          transition: all ease .3s;

          &:hover {
            border-color: ${Colors.background.blue};
            color: ${Colors.background.blue};
          };

          &:focus {
            border-color: ${Colors.background.blue};
            color: ${Colors.background.blue};
          };

          &:active {
            border-color: ${Colors.background.blue};
            color: ${Colors.background.blue};
          };

          &:disabled {
            border-color: ${Colors.background.blue};
            color: ${Colors.background.blue};
            cursor: default;
          };
      `;

    case "unbordered":
      return css`
          border-width: 1px;
          border-style: solid;
          border-color: transparent;
          background-color: transparent;
          color: ${Colors.background.blue};
          transition: all ease .3s;

          &:hover {
            border-color: ${Colors.background.blue};
            color: ${Colors.background.blue};
          };

          &:focus {
            border-color: ${Colors.background.blue};
            color: ${Colors.background.blue};
          };

          &:active {
            border-color: ${Colors.background.blue};
            color: ${Colors.background.blue};
          };

          &:disabled {
            border-color: transparent;
            color: ${Colors.background.blue};
            cursor: default;
          };
      `;

    case "text":
      return css`
          font-family: 'Raleway', sans-serif;
          padding: 0;
          border: none;
          font-weight: 400;
          background-color: transparent;
          color: ${Colors.background.blue};
          transition: color ease .3s;

          &:hover {
            color: ${Colors.background.blue};
          };

          &:focus {
            color: ${Colors.background.blue};
          };

          &:active {
            color: ${Colors.background.blue};
          };

          &:disabled {
            color: ${Colors.background.blue};
            cursor: default;
          };
      `;
  }
};

export const StyledButton = styled.button`
    font-family: 'Raleway', sans-serif;
    border-radius: 3px;
    display: inline-block;
    font-weight: 600;
    border: 0;
    cursor: pointer;
    line-height: 1;
    outline: none;
    box-sizing: border-box;

    ${(props:Props) => props.size && handleButtonSize(props.size)}

    ${(props:Props) => props.view && handleButtonView(props.view)}

    ${(props: Props) => (props.icon && props.icon !== '') && css`
      display: flex;
      align-items: center;
    `}
`;
