import React, { FC } from 'react';

import { PortfolioHeader } from './PortfolioHeader';
import { PortfolioContent } from './PortfolioContent';
import { HeaderDataType } from 'components/Components/PortfolioSection/PortfolioHeader/PortfolioHeader';
import { ContentItem } from 'components/Components/PortfolioSection/PortfolioContentItem/PortfolioContentItem';
import { Animation } from 'common/Animation';

interface Props {
  header: HeaderDataType,
  data: ContentItem[],
  showPoints: boolean
}

export const PortfolioSection:FC<Props> = ({ header, data, showPoints }) => {

  return (
    <>
      <Animation fadeInUp>
        <PortfolioHeader header={header} showPoints={showPoints} />
      </Animation>
      <PortfolioContent data={data} />
    </>
  );
};
