import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  max-width: 120px;
  height: 36px;
  margin: 0 auto;
  img {
    width: 100%;
    height: 100%;
  }
`;
