import styled from 'styled-components';
import { TypographySubtitle, TypographyTitle, TypographySmallerText } from "theme/Thypography";
import { Colors } from 'theme/colors';
import { Breakpoints } from 'theme/breakpoints';

export const Wrapper = styled.div`
  text-align: center;
`;

export const Subtitle = styled(TypographySubtitle)`
  color: ${Colors.text.lightBlue};
  margin-bottom: 20px;
  @media screen and (max-width: ${Breakpoints.tablet}) {
    margin-bottom: 6px;
  }
`;

export const Title = styled(TypographyTitle)`
  color: ${Colors.text.black};
  margin-bottom: 20px;
  @media screen and (max-width: ${Breakpoints.tablet}) {
    margin-bottom: 12px;
  }
`;

export const PointsContainer = styled.div`
  display: grid;
  grid-template-columns:
    minmax(100px, 1fr)
    minmax(100px, 1fr)
    minmax(100px, 1fr);
  grid-row-gap: 12px;
  grid-column-gap: 6px;
  @media screen and (max-width: ${Breakpoints.tablet}) {
    margin-top: 12px;
  }
`;

export const PointItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const PointIcon = styled.div`
  width: 12px;
  height: 12px;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const PointText = styled(TypographySmallerText)`
  color: ${Colors.text.darkGrey};
  margin-left: 8px;
  font-size: 14px;
`;


