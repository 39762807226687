import React from 'react';
import { Wrapper } from './styles';
import { MainContainer } from 'wrappers/MainContainer';

import { PortfolioSection as PortfolioSectionContent } from 'components/Components/PortfolioSection';
import { useTranslation } from "utils/i18n";

export const PortfolioSection = () => {
  const { t } = useTranslation();

  const data = {
    header: {
      title: t('portfolioTitle'),
      points: [
        t('portfolioPoint1'),
        t('portfolioPoint2'),
        t('portfolioPoint3'),
        t('portfolioPoint4'),
        t('portfolioPoint5'),
        t('portfolioPoint6'),
      ]
    },

    data: [
      {
        subtitle: t('portfoliodData1Subtitle'),
        title: t('portfoliodData1Title'),
        text: t('portfoliodData1Text'),
        buttonLink: '',
        imageSrc: 'dataStorage',
        reversed: false,
        new:false
      },
      {
        subtitle: t('portfoliodData2Subtitle'),
        title: t('portfoliodData2Title'),
        text: t('portfoliodData2Text'),
        buttonLink: '',
        imageSrc: 'businessProcess',
        reversed: true,
        new:false
      },
      {
        subtitle: t('portfoliodData3Subtitle'),
        title: t('portfoliodData3Title'),
        text: t('portfoliodData3Text'),
        buttonLink: '',
        imageSrc: 'dataVisualiztion',
        reversed: false,
        new:false
      },
      {
        subtitle: t('portfoliodData4Subtitle'),
        title: t('portfoliodData4Title'),
        text: t('portfoliodData4Text'),
        buttonLink: '',
        imageSrc: 'forecastingSystems',
        reversed: true,
        new:false
      },
      {
        subtitle: t('portfoliodData5Subtitle'),
        title: t('portfoliodData5Title'),
        text: t('portfoliodData5Text'),
        buttonLink: '',
        imageSrc: 'recommendationSystems',
        reversed: false,
        new:false
      },
      {
        subtitle: t('portfoliodData6Subtitle'),
        title: t('portfoliodData6Title'),
        text: t('portfoliodData6Text'),
        buttonLink: '',
        imageSrc: 'intelligentChatBots',
        reversed: true,
        new:false
      },
    ]
  };

  return (
    <Wrapper id="portfolio">
      <MainContainer>
        <PortfolioSectionContent
          header={data.header}
          data={data.data}
          showPoints={false}
        />
      </MainContainer>
    </Wrapper>
  );
};
