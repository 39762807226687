const enTranslation = {
  // currentValue: "Current value is {{value}}",
  // HEADER
  headerNavBarLink1: "Main",
  headerNavBarLink2: "About Us",
  headerNavBarLink3: "Services",
  headerNavBarLink4: "Technologies",
  headerNavBarLink5: "Clients",
  headerNavBarLink6: "Projects",
  headerNavBarLink7: "Products",
  headerLogoText: "Datalize",
  headerLanguage: "Рус",
  // MAIN SECTION TEXT
  mainSectionTitle: 'Data science solutions for your business',
  mainSectionDescription: 'Houzez is an innovative real estate WordPress theme that helps to ensure your website’s success in this super-competitive market.',
  mainSectionButtonText: 'Contacts',
  mainSectionContactsWithUs: 'Сontact us',
  // ABOUT US SECTION
  aboutUsTitle: 'About Us',
  aboutUsText: 'Datalize company provides full range of software development, support and maintenance services. We specialize in implementing Data Science solutions, automating and intellectualizing business processes, conducting statistical researches focused on extracting strategically important dependencies and patterns in data of your business.',
  // PORTFOLIO
  portfolioSubtitle: "Welcome to Houzez 2.0",
  portfolioTitle: "Services",
  portfolioPoint1: "Data collection and storage",
  portfolioPoint2: "Business processes analysis",
  portfolioPoint3: "Development of data visualization systems",
  portfolioPoint4: "Development of forecasting systems",
  portfolioPoint5: "Development of recommendation systems",
  portfolioPoint6: 'Development of intelligent messengers',
  portfolioLinkText: "View All",
  portfoliodData1Subtitle: "COLLECTION AND STORAGE OF DATA",
  portfoliodData1Title: "Development of systems for collecting and storing data",
  portfoliodData1Text: "<p>Development of automated systems for collecting and storing your data from CMS / CRM and other business tools.</p><ul><li>defining the database structure</li><li>selection of the most suitable database for your business</li><li>deploying the database on a server</li><li>setting up automated collection</li><li>providing an interface for convenient interaction with the database</li><li>development of dashboards for interactive visual data analysis</li></ul><p>Collect data about your customers and partners, keep statistics of orders, analyze the efficiency of your managers and use this information to increase the efficiency of your business.</p> ",
  portfoliodData2Subtitle: "BUSINESS PROCESS ANALYSIS",
  portfoliodData2Title: "Defining and modeling of business processes",
  portfoliodData2Text: "<p>We provide services of formalizing and modelling business processes of your business in order to improve their efficiency. The BPMN methodology that we use in the analysis of business processes allows us to build a model business actions and processes and as a result, find bottlenecks and optimize overall efficiency of business.</p><p>Correctly configured and optimized business processes is the efficiency of your business.</p>",
  portfoliodData3Subtitle: "DATA VISUALIZATION SYSTEMS",
  portfoliodData3Title: "Development of data visualization systems",
  portfoliodData3Text: "<p>We offer development of data visualization systems, both interactive and static, thanks to which you will always keep your business under the radar, and conveniently analyze its dynamics. Track key metrics, analyze trends, and monitor performance with high-quality data visualizations.</p><p>Interactive visual analysis can extract up to 80% of the useful information from your data. Monitor and respond to changes on time.</p>",
  portfoliodData4Subtitle: "FORECASTING SYSTEMS",
  portfoliodData4Title: "Development of forecasting systems",
  portfoliodData4Text: "<p>We offer development of forecasting systems for your business services. The main areas of application of forecasting systems:</p><ul><li>forecasting future performance</li><li>forecasting indicators based on other factors</li><li>solving classification problems (scoring / whether a person would buy or not, etc.)</li><li>forecasting based on neural networks</li><li>development of automated forecasting systems for further implementation in business processes and interactive dashboards</li></ul><p>Knowing and evaluating future trends is the key to adapting to them before everybody else.</p>",
  portfoliodData5Subtitle: "RECOMMENDATION SYSTEMS",
  portfoliodData5Title: "Development of recommendation systems",
  portfoliodData5Text: "<p>We are engaged in the development of recommendation systems aimed at improvement of the efficiency of sales of goods and services on your website. Also, with the help of recommendation systems, you can more effectively target your products to customers who are more likely to want them. The main types of recommender systems that we offer are:</p><ul><li>user-to-user</li><li>item-to-item</li><li>collaborative filtering</li></ul><p>Target your products to the right customers and increase conversions.</p>",
  portfoliodData6Subtitle: "INTELLIGENT CHAT BOTS",
  portfoliodData6Title: "Development of intelligent chat bots",
  portfoliodData6Text: "<p>Create your customer assistant. Sell your products and services through your Telegram bot, make interactive communication with your services possible. We are ready to offer you an intelligent chat bot or create a store of goods / services in the form of a Telegram bot, which can automatically offer suitable products to the customers.</p><p>Telegram bot is not only an application for communication, but also a powerful resource for promoting and increasing sales of your goods and services.</p>",

  portfoliodDataNew: "New",
  // SERVICES SECTION
  servicesTitle: "Other products and services ",
  // ACCORDION DATA
  accordionTitleOne: "Price Scanner",
  accordionDescriptionOne1: "A tool that allows you to track market prices of goods and services offered by competitors. Price Scanner visualizes the dynamics of market prices of goods and services you are interested in, and also offers various statistics: analysis of prices, dynamics of producer prices, discounts provided, price range and many other indicators.",
  accordionDescriptionOne2: "With the help of Price Scanner, you can optimize your pricing policy based on current market prices and competitors' actions, develop an effective system of discounts and always have an understanding of the current market situation.",
  accordionTitleTwo: "Fuzzy Discounts",
  accordionDescriptionTwo1: "Automated system of discounts calculation based on the customer's current shopping cart and required level of return on sales.",
  accordionDescriptionTwo2: "It allows you to automatically determine the level of discounts for each individual client, depending on such indicators as profit, volume of purchases, profitability of the current basket of goods, etc.",
  accordionDescriptionTwo3: " This approach will turn your store into an interactive application that customers and buyers always love.",
  accordionTitleThree: "Sales Analyzer",
  accordionDescriptionThree1: "Real-time sales data analysis and visualization system. The main aim of this service is to analyze and forecast the volume of sales of goods and services in retail outlets or online stores. ",
  accordionDescriptionThree2: "The system will make it possible to determine how many goods you need to have in stocks based on the current market situation, identify the most popular and profitable product groups for each facility, optimize inventory management, and predict potential revenue.",
  accordionDescriptionThree3: "Visualization of the sales volumes of the most popular commodity items will increase business efficiency.",
  accordionTitleFour: "Automatic Trading Platform",
  accordionDescriptionFour1: "We offer the development of automated trading systems on various cryptocurrency exchanges using the required risk management tools.",
  accordionDescriptionFour2: "Test your strategy, look for ways to optimize it, and run it on a remote server to capitalize on your strategy 24/7.",
  accordionTitleFive: "Advertising Analyzer",
  accordionDescriptionFive1: "Conducting an analysis of the effectiveness of advertising used on various platforms. This will determine the most effective ways of advertising, targeting, attracting customers. This analysis will help you find the audience with the highest purchasing power, and also identify the effectiveness of changes on the website, retail facility, in the approach of managers to clients and other aspects of doing business.",
  accordionTitleSix: "Startup Market Analyzer",
  accordionDescriptionSix1: "Do you want to open a business or expand an existing one, but doubt its profitability? Datalize offers you a service for analyzing the market to determine the current price level, identify the main competitors, as well as their strengths and weaknesses, compare suppliers and their terms, average level of return on sales, the most promising advertising methods, seasonality and the most effective periods of business.",
  accordionTitleSeven: "Client scanning system",
  accordionDescriptionSeven1: "This is a tool based on information received from CCTV cameras of shopping facilities, which will be able to identify: • total traffic by months/days/hours; • traffic of regular customers based on face scanning; • potential basket of goods of regular customers; • the effectiveness of the marketing unit based on visualization of traffic data.",
  accordionTitleEight: "Automated voice consultant",
  accordionDescriptionEight1: "One of the most popular ways to reduce the load on your call center. It will help you differentiate customer requests by the appropriate managers, answer some customer questions automatically, and improve the overall efficiency of the support department.",
  accordionTitleNine: "Analysis of customer behavior",
  accordionDescriptionNine1: "A system that allows you to determine the sources of traffic on your site, the average time spent on the site, the most popular pages among customers, and the specifics of customer behavior. The system works on the basis of configuration of unique metrics and further analysis. Analysis of the behavior of buyers on the website allows you to adapt the offered goods and discounts the customer's personal preferences, as well as dynamically change the site's UI.",
  accordionTitleTen: "Business from scratch bundle",
  accordionDescriptionTen1: "This product is a bundle of our services: analysis of the required market of goods and services, determination of potential business performance, development of a marketing strategy, website or online store with a recommendation system and an automated discount mechanism, as well as subsequent maintenance and analysis of business efficiency.",
  accordionSecondTitleOne: "Risk analysis of financial instruments",
  accordionSecondDescriptionOne1: "We offer an automated system for assessing market risks of financial assets based on various methodologies used by the world's leading financial companies.",
  accordionSecondDescriptionOne2: "Customize the system according to your wishes, risk sensitivity and strategy requirements.",
  accordionSecondTitleTwo: "Analysis and implementation of scientific solutions",
  accordionSecondDescriptionTwo1: "Sometimes many questions require scientific answers. Our team will analyze the most important scientific developments to answer your question, as well as conduct our own research in various fields using mathematical and statistical apparatus.",
  accordionSecondDescriptionTwo2: "We will find the most optimal ways to implement the results in your business.",
  accordionSecondTitleThree: "Chat bots and intellectual assistants",
  accordionSecondDescriptionThree1: "Chatbots and smart assistants will help you optimize the process of communication with clients and make it more interactive. The most popular messengers, such as telegram, whatsapp and facebook messenger, have the ability to integrate bots. A chatbot can be used to this answers questions instantly, it allows you to integrate payment systems, placing orders by connecting the bot to the order management system used by your company and other features.",
  accordionSecondTitleFour: "Knowledge bases and fuzzy search",
  accordionSecondDescriptionFour1: "If your company has frequently asked questions or some other extensive information in which it is difficult to find an answer to the question being asked, then using statistical approaches, we will help you optimize the search process by introducing algorithms that will significantly increase the likelihood that the user will find what he's really looking for.",
  accordionSecondTitleFive: "REST-API Development for Data Science Algorithms",
  accordionSecondDescriptionFive1: "We offer the development of mathematical and statistical algorithms and Machine Learning models with the subsequent configuration of the REST-API, which allows you to make interaction with algorithms into your services or website possible.",
  accordionSecondTitleSix: "Fraud detection",
  accordionSecondDescriptionSix1: "In case a hacker gains access to a user's account, then, usually, he behaves differently from the owner, which can identified and therefore help accurately prevent unwanted actions. Dynamic analysis of user activity allows to identify changes in behavior patterns and block an account until the original owner regains access.",
  accordionSecondTitleSeven: "Prediction of machinery breakdown, preventive maintenance",
  accordionSecondDescriptionSeven1: "If you need an automated risk management system associated with equipment failure and depreciation, our company is ready to provide a solution that will allow you to predict potential failures of various equipment in real time.",
  accordionSecondTitleEight: "Web-scraping",
  accordionSecondDescriptionEight1: "We offer services for the development of automated systems for scraping and processing data from various websites with their subsequent storage in a database. For example, if you would like to analyze user comments and reviews, product ratings on marketplaces or any other information that can only be obtained by collecting from a website, then this is the solution for you.",
  accordionSecondTitleNine: "Automation of business planning and ERP systems",
  accordionSecondDescriptionNine1: "We offer the development and implementation of an ERP system for your business, as well as integrate existing platforms with full support and customization.",
  accordionSecondTitleTen: "Web design",
  accordionSecondDescriptionTen1: "We provide full range of web development services starting from design to its development and deploying it on a server with subsequent support. Our company will help you create unique design for your website, including using modern and most effective Web development tools, integrate necessary metrics for collecting data and subsequent analysis of the effectiveness of the website, as well as apply SEO to promote it in search engines.",
  // TECHNOLOGIES SECTION
  technologiesTitle: 'Technologies',
  technologiesDescription: 'Datalize technologies',
  technologiesText: 'Our employees can choose technology directions, study new technologies both independently and in interest groups, and apply their knowledge in practice.',
  // OUR CLIENTS
  ourClientsTitle: 'Clients',
  ourClientsText: 'We work with local and global companies from different industries, which allows our employees to gain unique experience, creating full-featured solutions tailored to the characteristics and needs of our client’s business.',
  // OUR PROJECTS
  ourProjectTitle: "Projects",
  ourProjectOne: "An analysis was carried out that identifies the reasons for the churn of customers and the reduction in subscriptions to the news feed. Also, the well-being of the customer base was also assessed, depending on the value of their real estate, scraped off different sources which made it possible to create a system for predicting customer behavior.",
  ourProjectTwo: "Telegram bot was created that copies the main functions of applications and the website, also different models were implemented that predict user responses to the current question, which helped with additional involvement in the app, and also made it possible to provide more recommendations, regardless of the number of responses.",
  ourProjectThree: "A system for testing financial strategies was created, which made it possible to optimize and filter out various parameters used when trading on international financial markets.",
  ourProjectFour: "Analysis of the possibility of implementation and development of terms of requirements based on the results of scientific researches, aimed creation of unique proprietary system for predicting human well-being depending on weather and issuing recommendations for leveling negative health effects.",
  // FOOTER
  footerAllRightsCompany: "© 2020 Datalize",
  footerAllRightsReserved: "All rights reserved",
  footerCountryAndCity: "Prague, Czech Republic",
  footerContactsCollaboration: "Cooperation",
  footerContactsPhone: "+ 375 29 123 33 17",
  footerContactsEmail: "sales@datalize.org",
  // SUBSCRIBE DIALOG
  subscribeDialogTitle: "Leave your details and we will contact you",
  subscribeDialogEmailPlaceholder: "Enter Email",
  subscribeDialogSubscribe: "Submit",
  subscribeDialogSuccessedTitle: "Form submission was successful",
  subscribeDialogSuccessedDescription: "Thank you our employee will contact you soon.",
  subscribeDialogSuccessedButton: "Excellent!",
  subscribeDialogUnsuccessedTitle: "Form submission failed",
  subscribeDialogUnsuccessedDescription: "Sorry please try submitting the form later.",
  subscribeDialogUnsuccessedButton: "Okay",
  // VALIDATIONS
  validationEmailIsInvalid: 'Enter a valid Email',
  validationEmailRequired: 'Email is required',
  validationNameRequired: 'Name is required',
  validationLastNameRequired: 'Last name is required',
  validationPhoneRequired: 'Phone is required',
  // TELEGRAMS MESSAGE
  telegramSubscribeFormName: 'Form (Contact Us)',
  telegramSubscribeFormAction: 'asks to contact him',
  telegramContactsFormName: 'Form (Contacts Page)',
  ////////////////
  // CONTACTS PAGE
  ////////////////
  contactsTitle: "Our <br /> Contacts",
  contactsMainTitle: "Contact us",
  contactsFormNamePlaceholder: "Name",
  contactsFormLastNamePlaceholder: "Last Name",
  contactsFormPhonePlaceholder: "Phone",
  contactsFormEmailPlaceholder: "Email",
  contactsFormMessagePlaceholder: "Message",
  contactsFormSubmitButton: "Submit",
  contactsEmailsTitle: 'Emails',
  contactsEmailsGeneralLabel: 'General',
  contactsEmailsCooperationLabel: 'Cooperation',
  contactsEmailsTechnicalLabel: 'Technical',
  contactsSocialsTitle: 'Social media',
  contactsPhonesTitle: 'Telegram',
  // SEO BLOCK
  metaTitleMainPage: 'Data Science solutions for your business - Datalize',
  metaTitleContactsPage: 'Contacts - Datalize',
  metaDescriptionMainPage: 'Datalize company provides full range of software development, support and maintenance services. We specialize in implementing Data Science solutions, automating and intellectualizing business processes, conducting statistical researches focused on extracting strategically important dependencies and patterns in data of your business.',
  metaDescriptionContactsPage: 'We will find answers to your questions. Submit them through our form or ask directly. And also subscribe to our social networks - stay up to date with the news. We are always in touch.',
  metaCommonKeywords: '',
  metaOrGraphTitleMainPage: 'Data Science solutions for your business - Datalize',
  metaOrGraphDescriptionMainPage: 'Datalize company provides full range of software development, support and maintenance services. We specialize in implementing Data Science solutions, automating and intellectualizing business processes, conducting statistical researches focused on extracting strategically important dependencies and patterns in data of your business.',
  metaSiteMainPage: '@datalize',
  metaCanoncialMainPage: '',
  metaAlternateRuMainPage: '/ru/',
  metaAlternateEnMainPage: '',
  metaCanoncialContactsPage: '/contacts/',
  metaAlternateRuContactsPage: '/ru/contacts/',
  metaAlternateEnContactsPage: '/contacts/',
};

export default enTranslation;
