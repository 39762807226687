import styled, { css } from 'styled-components';
import { Field } from 'formik';
import { Colors } from 'theme/colors';

interface Props {
  fullwidth?: string
}

export const Container = styled.div``;

export const StyledInput = styled(Field)`
  padding: 11px 20px;
  border: 1px solid ${Colors.background.lightGrey};
  background-color: ${Colors.background.white};
  border-radius: 8px;
  margin-right: -4px;
  outline: none;
  color: ${Colors.text.black};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  font-family: 'Open Sans', sans-serif;

  ${(props:Props) => props.fullwidth === 'true' && css`
    width: 100%;
  `}

  ::placeholder {
    color: ${Colors.text.darkGrey};
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    font-family: 'Open Sans', sans-serif;
  }
`;

export const StyledLabel = styled.label``;

export const ErrorContainer = styled.div`
  width: 100%;
  min-height: 20px;
  margin-top: 8px;
`;

export const Error = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: ${Colors.text.error};
  font-family: 'Open Sans', sans-serif;
`;

export const ErrorLine = styled.div`
  width: 2px;
  height: 14px;
  background-color: ${Colors.text.error};
  border-radius: 8px;
  margin-right: 8px;
`;
