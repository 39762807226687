import styled, { css } from 'styled-components';
import { Colors } from 'theme/colors';
import { Breakpoints } from 'theme/breakpoints';

interface Props {
  expanded?: boolean
}


export const Wrapper = styled.div`
  width: 100%;
`;

export const AccordionItem = styled.div``;

export const AccordionContent = styled.div`
  display: flex;
  align-items: center;
  padding-right: 16px;
`;

export const AccordionTitle = styled.h5`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: auto;
  cursor: pointer;
  color: ${Colors.text.black};
  font-size: 14px;
  font-weight: 600;
  padding: 10px 6px 10px 20px;
  transition: color, border-color, transform .25s ease;

  &::after {
    display: inline-block;
    content: '';
    width: 6px;
    height: 6px;
    transform: rotate(45deg);
    border-style: solid;
    border-color: ${Colors.text.darkGrey};
    border-width: 0 1px 1px 0;
    padding: 1px;
  }

  &:hover {
    color: ${Colors.background.blue};
    &::after {
      border-color: ${Colors.background.blue};
    }
  }

  ${({ expanded }:Props) => expanded && css `
    color: ${Colors.background.blue};

    &::after {
      transform: rotate(-135deg);
      border-color: ${Colors.background.blue};
    }
  `}
`;

export const AccordionIcon = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 12px;
`;

export const AccordionDescriptionWrapper = styled.div`
  max-height: 0;
  transition: max-height 0.2s ease-out;
  overflow: hidden;

  ${({ expanded }:Props) => expanded && css `
      max-height: 500px;
      transition: max-height .2s ease-in;
  `}
`;

export const AccordionDescriptionContainer = styled.div`
  padding: 10px 20px 20px 0;

  @media screen and (max-width: ${Breakpoints.tablet}) {
    padding: 10px 0 20px 0 ;
  }
`;

export const AccordionDescription = styled.p`
  color: ${Colors.text.darkGrey};
  text-align: left;
  text-indent: 20px;

  & + & {
    margin-top: 8px;
  }

  @media screen and (max-width: ${Breakpoints.tablet}) {
    text-align: justify;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${Colors.text.lightGrey};
`;
