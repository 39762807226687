import React, { FC, useState } from 'react';
import { Wrapper, Form, Row, Col, FullWithCol, ActionsContainer } from './styles';
import { Input } from 'common/Input';
import { TextArea } from 'common/TextArea';
import { Button } from 'common/Button';
import { Formik } from 'formik';
import { useTranslation } from "utils/i18n";
import ContactsFormSchema from 'utils/validation-schema/ContactsForm';
import { sendGoogleAnalyticsMetrik } from 'utils/GA';

interface ContactsFormValues {
  name: string,
  lastName: string,
  phone: string,
  email: string,
  message: string
}

interface Props {
  showSuccessNotify?: any,
  showUnsuccessNotify?: any
}

export const ContactsForm:FC<Props> = ({ showSuccessNotify, showUnsuccessNotify }) => {
  const { t } = useTranslation();

  const initialFormValue: ContactsFormValues = {
    name: '',
    lastName: '',
    phone: '',
    email: '',
    message: ''
  };

  const [loading, setLoading] = useState<boolean>(false);

  const sendToTelegram = async (values:any) => {
    const { name, lastName, email, message, phone } = values;

    const alarmerBotUrl = 'https://alarmerbot.ru';
    const alarmerApiKey = process.env.REACT_APP_ALARMER_API_KEY || '52ec8a-4767ed-4c02da';

    const telegramMessage = `${t('telegramContactsFormName')}: Email: ${email}, Имя: ${name}, Фамилия: ${lastName}, Телефон: ${phone}, Сообщение: ${message}`;

    const dataToAnalytics = {
      'user_data': `Email: ${email}, FullName: ${lastName} ${name}`
    };

    try {
      await fetch(`${alarmerBotUrl}?key=${alarmerApiKey}&message=${telegramMessage}`, {
        method: 'GET',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      });
      sendGoogleAnalyticsMetrik('send_contact_form', 'contact_form', dataToAnalytics);
    } catch(error) {
      throw error;
    }
  };

  const sendToEmail = async (values:any) => {
    const { name, lastName, email, message, phone } = values;
    const formSubmitUrl = 'https://formspree.io/f/';
    const emailId = 'mknpzrqk';
    const urlForRequest = `${formSubmitUrl}${emailId}`;
    const dataToEmail:any = {
      name,
      lastName,
      email,
      phone,
      formName: 'Форма - Связаться с нами(Страница контакты)'
    };
    if(message) {
      dataToEmail.message = message;
    }
    try {
      await fetch(urlForRequest, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify(dataToEmail)
      });

    } catch(error) {
      throw error;
    }
  };

  const submit = async (values: any, actions: any) => {
    try {
      setLoading(true);
      await sendToEmail(values);
      await sendToTelegram(values);
      showSuccessNotify();
      actions.resetForm();
    } catch (error) {
      console.error(error);
      showUnsuccessNotify();
    } finally {
      setLoading(false);
      actions.resetForm();
    }
  };

  const validationMessages = {
    emailInvalid: t('validationEmailIsInvalid'),
    emailRequired: t('validationEmailRequired'),
    nameRequired: t('validationNameRequired'),
    lastNameRequired: t('validationLastNameRequired'),
    phoneRequired: t('validationPhoneRequired')
  };

  return (
    <Wrapper>
      <Formik
        initialValues={initialFormValue}
        validationSchema={ContactsFormSchema(validationMessages)}
        onSubmit={(values, actions) => submit(values, actions)}
      >
        {
          (props: any) => (
            <Form onSubmit={props.handleSubmit}>
              <Row>
                <Col>
                  <Input
                    form={props}
                    name="name"
                    fullWidth="true"
                    placeholder={t('contactsFormNamePlaceholder')}
                  />
                </Col>
                <Col>
                  <Input
                    form={props}
                    name="lastName"
                    fullWidth="true"
                    placeholder={t('contactsFormLastNamePlaceholder')}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Input
                    form={props}
                    name="phone"
                    fullWidth="true"
                    placeholder={t('contactsFormPhonePlaceholder')}
                  />
                </Col>
                <Col>
                  <Input
                    form={props}
                    name="email"
                    fullWidth="true"
                    placeholder={t('contactsFormEmailPlaceholder')}
                  />
                </Col>
              </Row>
              <Row>
                <FullWithCol>
                  <TextArea
                    form={props}
                    name="message"
                    placeholder={t('contactsFormMessagePlaceholder')}
                  />
                </FullWithCol>
              </Row>
              <ActionsContainer>
                <Button
                  submitBtn
                  size="large"
                  disabled={loading}
                  text={t('contactsFormSubmitButton')}
                />
              </ActionsContainer>
            </Form>
          )
        }
      </Formik>
    </Wrapper>
  );
};
