import styled from 'styled-components';
import { Colors } from 'theme/colors';
import { Breakpoints } from 'theme/breakpoints';

export const Wrapper = styled.div`
  background-color: ${Colors.background.white};
  padding: 120px 0 110px 0;

  @media screen and (max-width: ${Breakpoints.tablet}) {
    padding: 60px 0 55px 0;
  }

  @media screen and (max-width: ${Breakpoints.mobile}) {
    padding: 40px 0 40px 0;
  }
`;
