import React, { FC } from 'react';
import { Wrapper } from './styles';

import { TextBlock } from 'components/Components/AboutUsContent/TextBlock';
import { ImageBlock } from 'components/Components/AboutUsContent/ImageBlock';

interface Props {
  data: {
    title?: string,
    description?: string,
    text?: string,
    image: string
  }
}

export const AboutUsContent: FC<Props> = ({ data }) => {
  const { title, description, text, image } = data;
  return (
    <Wrapper>
      <TextBlock title={title} description={description} text={text}  />
      <ImageBlock image={image} />
    </Wrapper>
  );
};

AboutUsContent.defaultProps = {
  data: {
    title: '',
    description: '',
    text: '',
    image: ''
  }
};
