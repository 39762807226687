import React from 'react';
import { Wrapper } from './styles';
import { MainContainer } from 'wrappers/MainContainer';
import { TechnologiesContent } from 'components/Components/TechnologiesContent';
import { useTranslation } from "utils/i18n";
import { Animation } from 'common/Animation';

export const TechnologiesSection = () => {
  const { t } = useTranslation();
  const data = {
    title: t('technologiesTitle'),
    description: t('technologiesDescription'),
    text: t('technologiesText')
  };

  const icons = [
    'mongo',
    'apache',
    'amazon',
    'nginx',
    'numpy',
    'mysql',
    'pandas',
    'docker',
    'keras',
    'power',
    'linux',
    'scickit',
    'pytorch',
    'scipy',
    'theano',
    'tensorflow',
    'matplotlib',
    'python',
    'html',
    'css',
    'sass',
    'js',
    'typescript',
    'react',
    'vue',
    'node',
  ];
  return (
    <Wrapper id="technologies">
      <MainContainer>
        <Animation fadeInUp>
          <TechnologiesContent data={data} icons={icons} />
        </Animation>
      </MainContainer>
    </Wrapper>
  );
};
