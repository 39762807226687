import React, { FC } from 'react';
import { Wrapper } from './styles';
import { Images } from 'utils/images';

interface Props {
  icon: string;
}

export const Icon:FC<Props> = ({ icon }) => (
  <Wrapper>
    <img src={Images.technologies[icon]} alt={icon} />
  </Wrapper>
);
