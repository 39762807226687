import React, { FC, EventHandler, useRef } from 'react';
import {
  Wrapper,
  Container,
  Top,
  CloseButton,
  CLoseButtonLines,
  Content,
  Bottom,
  LinkItem,
  StyledLink,
  RouterLink
} from './styles';
import useOnClickOutside from 'utils/hooks/onClickOutside';
import { useTranslation } from "utils/i18n";
import { Logo } from 'common/Logo';
import { Button } from 'common/Button';
import { NavBarLink } from 'components/Components/HeaderContent/HeaderNavbar/HeaderNavbar';

interface Props {
  menu: NavBarLink[],
  onClick: EventHandler<any>;
  changeLanguage: Function;
  isOpen: boolean;
}


export const HeaderMobileMenu: FC<Props> = ({ onClick, changeLanguage, menu, isOpen }) => {
  const menuContainer = useRef(null);
  const { t, i18n } = useTranslation();

  useOnClickOutside(menuContainer, onClick);

  const formedLinkByLanguage = (link:string):string => {
    return i18n.language === 'ru' ? `/ru${link}` : link;
  };

  const linkRender = (link:NavBarLink):any => {
    if(link.href) {
      return <StyledLink onClick={link.action ? link.action : undefined} href={link.href}>{link.text}</StyledLink>;
    } else if (link.path) {
      return <RouterLink to={link.path}>{link.text}</RouterLink>;
    }
  };

  return (
    <Wrapper isOpen={isOpen}>
      <Container ref={menuContainer}>
        <Top>
          <Logo logoText={t('headerLogoText')} />
          <CloseButton onClick={onClick}>
            <CLoseButtonLines />
          </CloseButton>
        </Top>
        <Content>
          {
            menu.map(item => {
              return (
                <LinkItem key={item.id}>
                  {linkRender(item)}
                </LinkItem>
              );
            })
          }
        </Content>
        <Bottom>
          <Button
            view="default"
            size="large"
            type="link"
            to={formedLinkByLanguage('/contacts/')}
          >
            {t('mainSectionButtonText')}
          </Button>
        </Bottom>
      </Container>
    </Wrapper>
  );
};

HeaderMobileMenu.defaultProps = {
  menu: [],
  onClick: undefined,
  isOpen: false
};
