import styled from 'styled-components';
import { Breakpoints } from 'theme/breakpoints';
import { Colors } from 'theme/colors';
import { Images } from 'utils/images';

export const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 90px);
  margin-top: 90px;
  position: relative;

  @media screen and (max-width: ${Breakpoints.tablet}) {
    height: calc(100vh - 70px);
    margin-top: 70px;;
  }

  @media screen and (max-width: ${Breakpoints.mobile}) {
    background-image: url(${Images.mainPageBackgroundMobile});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

`;

export const Background = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  bottom: 15%;
  width: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  button, a {
    font-size: 28px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 20px 24px;

    @media screen and (max-width: ${Breakpoints.tablet}) {
      font-size: 20px;
      padding: 16px 20px;
    }
  }

  @media screen and (max-width: ${Breakpoints.mobile}) {
    bottom: 50%;

    button, a {
      padding: 20px 40px;
    }
  }
`;

export const MobileButton = styled.button`
  position: fixed;
  bottom: 2%;
  right: 8px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  z-index: 99999;
  border: 1px solid ${Colors.background.blue};
  background-color: ${Colors.background.blue};
  color: ${Colors.background.white};
  transition: all ease .3s;
  box-shadow: 0 2px 6px 0 rgba(13,33,57,1);
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${Colors.background.lightBlue};
    box-shadow: 0 2px 6px 0 rgba(25,50,95,1);
  };

  &:focus {
    background-color: ${Colors.background.lightBlue};
  };

  &:active {
    background-color: ${Colors.background.lightBlue};
  };

  &:disabled {
    background-color: ${Colors.background.lightGrey};
    box-shadow: none;
    cursor: default;
  };
`;

export const MobileButtonIcon = styled.img`
  width: 18px;
  height: 18px;
`;
