import React, { FC } from 'react';
import { StyledButton } from './styles';
import { Link } from 'react-router-dom';

interface P {
  view?: "default" | "bordered" | "unbordered" | "text";
  size?: "medium" | "small" | "large";
  type?: "button" | "link" | "submit";
  children?: any;
  disabled?: boolean;
  onClick?: React.EventHandler<any>;
  href?: string;
  isBlankedLink?: boolean;
  text?: string;
  to?:  any;
  submitBtn?:boolean;
}

export const Button: FC<P> = (
  {
    view,
    size,
    type,
    children,
    disabled,
    onClick,
    href,
    isBlankedLink,
    text,
    to,
    submitBtn,
    ...props
  }) => {

  const BaseLink = (
    <StyledButton
      view={view}
      size={size}
      type={type}
      as={'a'}
      href={href}
      target={isBlankedLink ? '_blank' : '_self'}
      {...props}
    >
      {text || children}
    </StyledButton>
  );

  const BaseButton = (
    <StyledButton
      view={view}
      size={size}
      type={submitBtn ? 'submit' : 'button'}
      disabled={disabled}
      onClick={onClick}
      {...props}
    >
      {text || children}
    </StyledButton>
  );

  const BaseRouterLink = (
    <Link to={to}>
      <StyledButton
        view={view}
        size={size}
        disabled={disabled}
        onClick={onClick}
        {...props}
      >
        {text || children}
      </StyledButton>
    </Link>
  );

  const chooseCorrectButton = () => {
    if(type === 'link' && href) {
      return BaseLink;
    } else if (type ==='link' && to) {
      return BaseRouterLink;
    } else {
      return BaseButton;
    }

  };

  return chooseCorrectButton();
};

Button.defaultProps = {
  view: 'default',
  size: 'medium',
  type: 'button',
  disabled: false,
  onClick: undefined,
  href: '',
  isBlankedLink: true,
  text: '',
  children: undefined,
  to: '',
  submitBtn: false
};
