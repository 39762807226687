import React, { useState } from 'react';
import { Wrapper } from './styles';
import { ContactsForm } from 'components/Components/ContactsFormContent/ContactsForm';
import { useTranslation } from "utils/i18n";
import { NotifyDialog } from "../../Dialogs/NotifyDialog";

export const ContactsFormContent = () => {
  const { t } = useTranslation();
  const [successDialogIsOpen, setSuccessDialogIsOpen] = useState<boolean>(false);
  const [unsuccessDialogIsOpen, setUnsuccessDialogIsOpen] = useState<boolean>(false);

  const successMessages = {
    title: t('subscribeDialogSuccessedTitle'),
    description: t('subscribeDialogSuccessedDescription'),
    button: t('subscribeDialogSuccessedButton')
  };

  const unsuccessMessages = {
    title: t('subscribeDialogUnsuccessedTitle'),
    description: t('subscribeDialogUnsuccessedDescription'),
    button: t('subscribeDialogUnsuccessedButton')
  };

  return(
    <>
      <NotifyDialog
        onClose={() => setUnsuccessDialogIsOpen(false)}
        isOpen={unsuccessDialogIsOpen}
        title={unsuccessMessages.title}
        description={unsuccessMessages.description}
        button={unsuccessMessages.button}
      />
      <NotifyDialog
        onClose={() => setSuccessDialogIsOpen(false)}
        isOpen={successDialogIsOpen}
        title={successMessages.title}
        description={successMessages.description}
        button={successMessages.button}
      />
      <Wrapper>
        <ContactsForm
          showSuccessNotify={() => setSuccessDialogIsOpen(true)}
          showUnsuccessNotify={() => setUnsuccessDialogIsOpen(true)}
        />
      </Wrapper>
    </>
  );
};
