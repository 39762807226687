import styled from 'styled-components';
import { Breakpoints } from 'theme/breakpoints';

export const Wrapper = styled.div`
  padding: 66px 0 44px 0;
  @media screen and (max-width: ${Breakpoints.mobile}) {
    padding: 32px 0 22px 0;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: ${Breakpoints.mobile}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
