import * as Yup from 'yup';

interface ValidationMessages {
  emailInvalid: string;
  emailRequired: string;
  nameRequired: string,
  lastNameRequired: string,
  phoneRequired: string,
}

const ContactsFormSchema = (messages: ValidationMessages) => Yup.object().shape({
  name: Yup.string().required(messages.nameRequired),
  lastName: Yup.string().required(messages.lastNameRequired),
  phone: Yup.string().required(messages.phoneRequired),
  email: Yup.string()
    .email(messages.emailInvalid)
    .required(messages.emailRequired),
  message: Yup.string()
});

export default ContactsFormSchema;
