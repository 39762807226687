import styled from "styled-components";
import { Link } from 'react-router-dom';
import { Colors } from 'theme/colors';
import { Breakpoints } from 'theme/breakpoints';

export const Wrapper = styled.nav`
  margin-right: 36px;

  @media screen and (max-width: ${Breakpoints.tablet}) {
    display: none;
  }
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  list-style-type: none;
`;

export const Listitem = styled.li`
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 18px;
  font-family: '-apple-system','BlinkMacSystemFont','Segoe UI','Roboto','Helvetica Neue','Arial','Noto Sans',sans-serif;
  & + & {
    margin-left: 24px;
  }
  :hover {
    a {
      color: ${Colors.text.lightBlue};
    }
  }
  a {
    color: ${Colors.text.black};
    transition: color ease .25s;
  }
`;

export const StyledLink = styled.a``;

export const RouterLink = styled(Link)``;
