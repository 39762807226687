import styled from 'styled-components';
import { Colors } from 'theme/colors';

interface Props {
  isSelected: boolean;
}


export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  margin-top: 48px;
  .slick-dots {
    bottom: -56px;
    .slick-active {
      li {
        background-color: ${Colors.background.blue };
      }
    }
  }
  .projects-slider {
    .slick-list {
      .slick-track {
        .slick-slide {
          div {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
`;

export const Dot = styled.li`
  display: inline-block;
  width: 12px !important;
  height: 12px !important;
  border: none;
  cursor: pointer;
  border-radius: 120px;
  background: ${Colors.background.lightGrey};
  margin: 0 4px !important;
`;
