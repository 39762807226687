import styled from 'styled-components';
import { Colors } from 'theme/colors';
import { Breakpoints } from 'theme/breakpoints';

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  min-height: 100vh;
  background-color: ${Colors.background.white};
  padding: 80px 0;

  @media screen and (max-width: ${Breakpoints.tablet}) {
    padding: 60px 0;
  }

  @media screen and (max-width: ${Breakpoints.mobile}) {
    padding: 40px 0;
  }
`;
