import React, { FC, useState, useEffect } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { RouteWithLayout } from "common/RouteWithLayout";
import Routes from 'routes';
import ScrollToTop from 'utils/hooks/scrollToTop';
import { LandingLayout } from 'layouts/LandingLayout';
import i18next from 'i18next';

import MainPage from 'pages/MainPage';
import ContactsPage from 'pages/ContactsPage';

interface Props {
  lng?: string
}

const MainRouter:FC<Props> = ({ lng = 'en' }) => {
  const subpath = lng === 'en' ? '' : `/${lng}`;

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    i18next.changeLanguage(lng || 'en').then(()=>setLoading(false));
  });
  if (loading) {
    return <></>;
  }

  return (
    <>
      <ScrollToTop />
      <Switch>
        <RouteWithLayout exact layout={LandingLayout} component={MainPage} path={subpath + Routes.mainPage} />
        <RouteWithLayout layout={LandingLayout} component={ContactsPage} path={subpath + Routes.contactsPage} />

        <Redirect to={Routes.mainPage} />
      </Switch>
    </>
  );
};

export default MainRouter;
