import React, { FC } from 'react';
import { Wrapper, Title, Text } from './styles';

interface Props {
  header: {
    title: string,
    text: string
  }
}

export const OurClientsHeader:FC<Props> = ({ header }) => (
  <Wrapper>
    <Title>{header.title}</Title>
    <Text>{header.text}</Text>
  </Wrapper>
);

OurClientsHeader.defaultProps = {
  header: {
    title: '',
    text: ''
  }
};
