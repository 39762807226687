import React, { FC } from 'react';
import { Wrapper, CloseButton, Container, Title, Description } from './styles';
import { Button } from 'common/Button';
import Modal from 'react-modal';
import './styles.css';

Modal.setAppElement('#modal-root');

interface Props {
  isOpen: boolean,
  onClose?: any,
  title?: string,
  description?: string,
  button?: string
}

export const NotifyDialog:FC<Props> = ({ isOpen, onClose, title, description, button }) => {
  return (
    <Modal
      className="Modal"
      overlayClassName="Overlay"
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick={true}
    >
      <Wrapper>
        <CloseButton type="button" onClick={onClose} />
        <Container>
          {title && <Title>{title}</Title>}
          {description && <Description>{description}</Description>}
          {button && <Button size="large" text={button} onClick={onClose} />}
        </Container>
      </Wrapper>
    </Modal>
  );
};
