import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from 'utils/i18n/locales/en';
import ruTranslation from "utils/i18n/locales/ru";

i18n.use(initReactI18next).init({
    fallbackLng: 'en',
    // debug: true,
    resources: {
        en: {
            translation: enTranslation,
        },
        ru: {
            translation: ruTranslation
        }
    },

    interpolation: {
        escapeValue: false,
    },
});

export type TranslationKeys = keyof typeof enTranslation

export * from './hook';
export default i18n;
