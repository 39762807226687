import styled from 'styled-components';
import { Breakpoints } from 'theme/breakpoints';

export const Wrapper = styled.div``;

export const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: #eeeeee;
  margin: 40px 0;

  @media screen and (max-width: ${Breakpoints.tablet}) {
    margin: 32px 0;
  }

  @media screen and (max-width: ${Breakpoints.mobile}) {
    margin: 32px 0;
  }
`;
