import { useEffect, useState } from 'react';

interface Props {
  threshold: number,
  triggerOnce: boolean
}

export function useInView({ threshold, triggerOnce }: Props) {
  // @ts-ignore
  const [ref, setRef] = useState<HTMLDivElement>(null);
  const [inView, setInView] = useState<boolean>(false);

  useEffect(() => {
      if (!ref) return;

      const intersectionOptions = {
        threshold: threshold || 0,
      };
      const observer = new IntersectionObserver((entries, obs) => {
        entries.forEach(entry => {
          setInView(entry.isIntersecting);
          if (triggerOnce && entry.isIntersecting) {
            obs.unobserve(ref);
          }
        });
      }, intersectionOptions);

      observer.observe(ref);
      return () => observer.unobserve(ref);
    },
    [threshold, triggerOnce, ref],
  );

  return [setRef, inView];
}
