import React, { FC } from 'react';
import { useTranslation } from "../utils/i18n";

import { MainSection } from 'components/Sections/MainSection';
import { AboutUsSection } from 'components/Sections/AboutUsSection';
import { PortfolioSection } from 'components/Sections/PortfolioSection';
import { ServicesSection } from 'components/Sections/ServicesSection';
import { TechnologiesSection } from 'components/Sections/TechnologiesSection';
import { OurClients } from 'components/Sections/OurClients';
import { OurProjects } from 'components/Sections/OurProjects';
import { MetaTags } from 'common/MetaTags';

const MainPage: FC = () => {
  const baseUrl = 'https://datalize.org';
  const { t } = useTranslation();

  const mainSectionData = {
    title: t('mainSectionTitle'),
    button: t('mainSectionContactsWithUs')
  };

  const seoSettings = {
    title: t('metaTitleMainPage'),
    metaDescription: t('metaDescriptionMainPage'),
    metaKeywords: t('metaCommonKeywords'),
    opGraphTitle: t('metaOrGraphTitleMainPage'),
    opGraphDescription: t('metaOrGraphDescriptionMainPage'),
    opGraphSite: t('metaSiteMainPage'),
    canonical: `${baseUrl}${t('metaCanoncialMainPage')}`,
    alternateRu: `${baseUrl}${t('metaAlternateRuMainPage')}`,
    alternateEn: `${baseUrl}${t('metaAlternateEnMainPage')}`,
    alternateDefault: `${baseUrl}${t('metaAlternateEnMainPage')}`,
    baseUrl
  };

  return (
    <>
      <MetaTags settings={seoSettings} />
      <MainSection data={mainSectionData} />
      <AboutUsSection />
      <PortfolioSection />
      <TechnologiesSection />
      <OurClients />
      <OurProjects />
      <ServicesSection />
    </>
  );
};

export default MainPage;
