import styled from 'styled-components';
import { Breakpoints } from 'theme/breakpoints';

export const Wrapper = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  padding: 10px;
  margin-left: 5%;
  border-radius: 12px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }

  @media screen and (max-width: ${Breakpoints.tablet}) {
    flex: 0 0 80%;
    max-width: 80%;
    padding: 0;
    margin-left: 0;
    margin-top: 24px;
  }
  @media screen and (max-width: ${Breakpoints.mobile}) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;
