import React, { FC } from 'react';
import { Wrapper, Container } from './styles';
import { FooterLeftBlock } from './FooterLeftBlock';
import { FooterRightBlock } from './FooterRightBlock';
import { SocialItemType } from 'components/Components/FooterContent/FooterLeftBlock/SocialsBlock/SocialsBlock';

interface Props {
  socials: SocialItemType[],
  telegrams: string[]
}

export const FooterContent:FC<Props> = ({ socials, telegrams }) => {
  return (
    <Wrapper>
      <Container>
        <FooterLeftBlock socials={socials} />
        <FooterRightBlock telegrams={telegrams} />
      </Container>
    </Wrapper>
  );
};

FooterContent.defaultProps = {
  socials: [],
  telegrams: []
};
