import styled from 'styled-components';
import { Colors } from 'theme/colors';
import { Breakpoints } from 'theme/breakpoints';

export const Wrapper = styled.div`
  padding: 48px 0 68px 0;

  @media screen and (max-width: ${Breakpoints.tablet}) {
    padding: 24px 0 55px 0;
  }
`;

export const Header = styled.h2`
  margin: 0 auto;
  max-width: 654px;
  padding: 20px;
  font-size: 30px;
  color: ${Colors.text.black};
  font-weight: 600;
  line-height: 1.3em;
  letter-spacing: -0.5px;
  text-align: center;
`;

export const Container = styled.div`
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: ${Breakpoints.mobile}) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: 920px) and (orientation: landscape) {
    flex-wrap: wrap;
  }
`;

export const Block = styled.div`
  width: calc(50% - 14px);
  & + & {
    margin-left: 28px;
  }

  @media screen and (max-width: ${Breakpoints.mobile}) {
    width: 100%;

    & + & {
      margin-left: 0;

    }
  }

  @media screen and (max-width: 920px) and (orientation: landscape) {
    width: 100%;

    & + & {
      margin-left: 0;

    }
  }
`;
