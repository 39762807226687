import React, { FC } from 'react';
import { Wrapper } from './styles';
import { Images } from 'utils/images';
import { Client } from "../OurClientsMain";

interface Props {
  icon: Client['icon']
  customStyle: Client['customStyle']
}

export const OurClientsIcon:FC<Props> = ({ icon, customStyle }) => (
  <Wrapper style={customStyle}>
    <img src={Images.clients[icon]} alt={icon} />
  </Wrapper>
);
