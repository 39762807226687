import React, { FC } from 'react';
import { Button, ButtonText } from './styles';
import { useTranslation } from "utils/i18n";
import { Images } from 'utils/images';

interface Props {
  onClick:Function
}

export  const HeaderLanguageButton:FC<Props> = ({ onClick }) => {
  const { t, i18n } = useTranslation();

  return(
    <Button onClick={() => onClick()}>
      <img src={Images.earth} alt={i18n.language} />
      <ButtonText>{t('headerLanguage')}</ButtonText>
    </Button>
  );
};

HeaderLanguageButton.defaultProps = {
  onClick: undefined
};
