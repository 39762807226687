import styled from 'styled-components';
import { Colors } from 'theme/colors';
import { Breakpoints } from 'theme/breakpoints';

export const Container = styled.button`
  margin: -6px 30px -6px -6px;
  cursor: pointer;
  padding: 6px;
  display: none;
  background: transparent;
  border: none;
  outline: none;
  @media screen and (max-width: ${Breakpoints.tablet}) {
    display: block;
  }
  @media screen and (max-width: ${Breakpoints.mobile}) {
    margin: -6px -6px -6px -6px;
  }
`;

export const BurgerBox = styled.div`
  width: 24px;
  height: 24px;
  display: inline-block;
  position: relative;
`;

export const Burger = styled.div`
  transition: transform .1s cubic-bezier(.55,.055,.675,.19),background .25s ease-in-out,-webkit-transform .1s cubic-bezier(.55,.055,.675,.19);
  display: block;
  position: absolute;
  top: 50%;
  width: 24px;
  height: 2px;
  background-color: ${Colors.background.blue};
  border-radius: 4px;
  :before,:after {
    height: 2px;
    background-color: ${Colors.background.blue};
    border-radius: 4px;
    position: absolute;
    content: '';
    display: block;
  }
  :before {
    width: 15px;
    top: -8px;
    transition: top .1s ease .14s,opacity .1s ease,background .25s ease-in-out;
  }
  :after {
    bottom: -8px;
    width: 19px;
    transition: bottom .1s ease .14s,transform .1s cubic-bezier(.55,.055,.675,.19),background .25s ease-in-out,-webkit-transform .1s cubic-bezier(.55,.055,.675,.19);
  }
`;
