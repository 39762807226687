import React, { FC } from 'react';
import { Wrapper } from './styles';
import { OurClientsIcon } from 'components/Components/OurClientsContent/OurClientsMain/OurClientsIcon';
import { CSSProperties } from "styled-components";

export interface Client {
  icon: string
  customStyle?: CSSProperties
}

interface Props {
  clients: Client[]
}

export const OurClientsMain:FC<Props> = ({ clients }) => (
  <Wrapper>
    {
      clients.map((client, index) => (
        <OurClientsIcon key={index} icon={client.icon} customStyle={client.customStyle} />
      ))
    }
  </Wrapper>
);
