import styled from 'styled-components';
import { Breakpoints } from 'theme/breakpoints';

export const Wrapper = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  margin-left: 5%;
  display: grid;
  grid-template-columns:
    minmax(50px, 1fr)
    minmax(50px, 1fr)
    minmax(50px, 1fr)
    minmax(50px, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  @media screen and (max-width: ${Breakpoints.tablet}) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-left: 0;
    margin-top: 24px;
    grid-template-columns:
      minmax(50px, 1fr)
      minmax(50px, 1fr)
      minmax(50px, 1fr)
      minmax(50px, 1fr);
    grid-column-gap: 2px;
    grid-row-gap: 24px;
  }

  @media screen and (max-width: ${Breakpoints.mobile}) {
    grid-template-columns:
      minmax(50px, 1fr)
      minmax(50px, 1fr);
    grid-column-gap: 0;
    grid-row-gap: 24px;
    justify-content: center;
  }

  @media screen and (max-width: ${Breakpoints.smallMobile}) {
    grid-template-columns: minmax(50px, 1fr);
  }
`;
