import React from 'react';
import { Wrapper, Title } from './styles';
import { MainContainer } from 'wrappers/MainContainer';
import { useTranslation } from "utils/i18n";
import { ContactsFormContent } from 'components/Components/ContactsFormContent';
import { Animation } from "common/Animation";

export const ContactsFormSection = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Animation fadeInUp>
        <MainContainer>
          <Title>{t('contactsMainTitle')}</Title>
          <ContactsFormContent />
        </MainContainer>
      </Animation>
    </Wrapper>
  );
};
