import React, { FC } from 'react';
import { Link, Icon, Social } from './styles';
import { Images } from 'utils/images';
import { CSSProperties } from "styled-components";

export interface Social {
  icon: string,
  link: string,
  social: string
  customStyle?: CSSProperties
}

export const ContactsSocial:FC<Social> = ({ icon, link, social, customStyle }) => {
  return(
      <Link target="_blank" href={link}>
        <Icon style={customStyle} src={Images.socials[icon]} alt={social} />
        <Social>{social}</Social>
      </Link>
  );
};
