import styled from 'styled-components';
import { Breakpoints } from "../../../theme/breakpoints";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;


export const LogoContainer = styled.div`
  @media screen and (max-width: ${Breakpoints.tablet}) {
    display: none;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;

  a {
    @media screen and (max-width: ${Breakpoints.tablet}) {
      display: none;
    }
  }

`;

export const InvisivleLinksBlock = styled.div`
  opacity: 0;
  visibility: hidden;
  position: fixed;
  left: 100000px;
`;

export const InvisibleLink = styled.a`
  opacity: 0;
  visibility: hidden;
`;
