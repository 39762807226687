import React, { FC } from 'react';
import {
  Wrapper,
  Title,
  Email,
  TelegramIcons,
  TelegramLink
} from './styles';
import { useTranslation } from "utils/i18n";
import { Images } from "utils/images";

interface Props {
  telegrams: string[]
}

export const FooterRightBlock:FC<Props> = ({ telegrams }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Title>{t('footerContactsCollaboration')}</Title>
      <TelegramIcons>
        {
          telegrams.map((telegram) => (
            <TelegramLink rel="noopener noreferrer" key={telegram} target="_blank" href={telegram}>
              <img src={Images.socials.telegramIcon} alt="telegram" />
            </TelegramLink>
          ))
        }
      </TelegramIcons>
      <Email href={`mailto:${t('footerContactsEmail')}`}>{t('footerContactsEmail')}</Email>
    </Wrapper>
  );
};

FooterRightBlock.defaultProps = {
  telegrams: []
};
