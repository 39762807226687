import * as Yup from 'yup';

interface ValidationMessages {
  emailInvalid: string;
  emailRequired: string;
}

const SubscribeFormSchema = (messages: ValidationMessages) => Yup.object().shape({
  email: Yup.string()
    .email(messages.emailInvalid)
    .required(messages.emailRequired)
});

export default SubscribeFormSchema;
