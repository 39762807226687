import styled from 'styled-components';
import { Images } from 'utils/images';
import { TypographySmallerText, TypographySmallerTitle } from "../../../../theme/Thypography";
import { Colors } from 'theme/colors';
import { Breakpoints } from 'theme/breakpoints';

interface Props {
  background: string;
}

export const Wrapper = styled.div`
  width: calc(100% - 20px);
  height: auto;
  padding: 32px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 476px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: antiquewhite;
  background-image: ${({ background }:Props) => `url("${Images.projects[background || '']}")` };
  z-index: 1;
  border-radius: 12px;
  cursor: pointer;

  @media screen and (max-width: 1120px) {
    min-height: 520px;
  }

  @media screen and (max-width: 740px) {
    max-width: 500px;
    min-height: 497px;
  }

  @media screen and (max-width: ${Breakpoints.mobile}) {
    padding: 40px 20px;
  }

  @media screen and (max-width: ${Breakpoints.smallMobile}) {
    padding: 20px 10px;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
  border-radius: 12px;
`;

export const Name = styled(TypographySmallerTitle)`
  color: ${Colors.background.white};
  font-size: 32px;
  font-weight: 800;
  z-index: 4;
  text-align: center;
`;

export const Text = styled(TypographySmallerText)`
  margin-top: 32px;
  color: ${Colors.background.white};
  z-index: 4;
  text-align: center;
  @media screen and (max-width: ${Breakpoints.smallMobile}) {
    margin-top: 16px;
  }
`;

export const Technology = styled(TypographySmallerTitle)`
  margin-top: auto;
  color: ${Colors.background.white};
  font-size: 20px;
  z-index: 4;
  text-align: center;
  padding-top: 20px;
  @media screen and (max-width: ${Breakpoints.smallMobile}) {
    padding-top: 10px;
  }
`;

