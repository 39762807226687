import React, { FC } from 'react';
import { Container, StyledLabel, StyledTextArea, ErrorContainer, Error, ErrorLine } from './styles';
import { Field } from 'formik';

interface Props {
  label?: string;
  placeholder?: string;
  name: string;
  form?: any;
  disabled?:boolean;
}

export const TextArea:FC<Props> = ({ label, name,placeholder, disabled }) => {
  return(
    <Container>
      {label && <StyledLabel>{label}</StyledLabel>}
      <Field name={name}>
        {({ field, form:{ touched,errors } }:any) => (
          <div>
            <StyledTextArea
              {...field}
              placeholder={placeholder}
              disabled={disabled}
            />
            <ErrorContainer>
              {(errors[name] && touched[name]) && <Error><ErrorLine />{errors[name]}</Error>}
            </ErrorContainer>
          </div>
        )}
      </Field>
    </Container>
  );
};
