import styled from 'styled-components';
import { Colors } from 'theme/colors';
import { TypographySmallerTitle, TypographyText } from 'theme/Thypography';
import { Breakpoints } from 'theme/breakpoints';

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
  z-index: 80;
  background-color: #ffffff;
  padding: 40px 20px 30px 20px;
`;

export const CloseButton = styled.button`
  width: 32px;
  height: 32px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;

  &:hover {
   &:before, &:after {
      background-color: ${Colors.background.lightBlue};
    }
  }

  &:before, &:after {
  position: absolute;
  content: ' ';
  height: 33px;
  width: 3px;
  background-color: ${Colors.background.blue};
  transition: background-color ease .25s;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

export const Container = styled.div`
  text-align: center;
  margin-top: 24px;
`;

export const Title = styled(TypographySmallerTitle)`
  @media screen and (max-width: ${Breakpoints.mobile}) {
    font-size: 20px;
  }
`;

export const Description = styled(TypographyText)`
  margin: 20px 0 20px 0;
`;
