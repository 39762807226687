import React, { FC } from 'react';
import { useInView } from 'utils/hooks/useIntersect';
import { Container } from './styles';

interface Props {
  children: React.ReactNode,
  fadeInUp?: boolean,
  fade?: boolean
}

export const Animation: FC<Props> = ({ children, fadeInUp, fade }) => {

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  return (
    // @ts-ignore
    <Container
      ref={ref}
      visible={inView}
      fadeInUp={fadeInUp}
      fade={fade}
    >
      {children}
    </Container>
  );
};
