import styled from 'styled-components';
import { Breakpoints } from 'theme/breakpoints';

export const Wrapper = styled.div`
  max-width: 1264px;
  width: 100%;
  margin: 0 auto;
  padding: 0 48px;

  @media screen and (max-width: ${Breakpoints.mobile}) {
     padding: 0 20px;
  }
`;
