import React from 'react';
import { Wrapper } from './styles';
import { MainContainer } from 'wrappers/MainContainer';
import { AboutUsContent } from 'components/Components/AboutUsContent';
import { useTranslation } from "utils/i18n";
import { Animation } from 'common/Animation';

export const AboutUsSection = () => {
  const { t } = useTranslation();
  const data = {
    title: t('aboutUsTitle'),
    text: t('aboutUsText'),
    image: 'aboutUsImage'
  };
  return (
    <Wrapper id="about">
      <MainContainer>
        <Animation fadeInUp>
          <AboutUsContent data={data} />
        </Animation>
      </MainContainer>
    </Wrapper>
  );
};
