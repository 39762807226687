import React from 'react';
import { Wrapper, Title } from './styles';
import { MainContainer } from 'wrappers/MainContainer';

import { OurProjectCarousel } from 'components/Components/OurProjectsContent/OurProjectCarousel';
import { Animation } from 'common/Animation';

import { useTranslation } from "utils/i18n";

export const OurProjects = () => {
  const { t } = useTranslation();

  const projects = [
    {
      name: 'Vertex Foods',
      text: t('ourProjectOne'),
      technologies: 'Python, TensorFlow',
      background: 'vertexFoods'
    },
    {
      name: 'Lifeaddwiser',
      text: t('ourProjectTwo'),
      technologies: 'Python, Linux, Nginx, UWSGI',
      background: 'lifeaddwiser'
    },
    {
      name: 'BitActive',
      text: t('ourProjectThree'),
      technologies: 'Python',
      background: 'bitActive'
    },
    {
      name: 'Viv Dev',
      text: t('ourProjectFour'),
      technologies: 'Python',
      background: 'vivDev'
    }
  ];

  return (
    <Wrapper id="projects">
      <MainContainer>
        <Animation fadeInUp>
          <Title>{t('ourProjectTitle')}</Title>
        </Animation>
        <Animation fadeInUp>
          <OurProjectCarousel projects={projects} />
        </Animation>
      </MainContainer>
    </Wrapper>
  );
};
