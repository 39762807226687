import styled from 'styled-components';
import { TypographySubtitle } from "theme/Thypography";
import { Colors } from 'theme/colors';
import { Breakpoints } from 'theme/breakpoints';

export const Wrapper = styled.div`
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media screen and (max-width: ${Breakpoints.mobile}) {
    align-items: center;
    justify-content: center;
  }
`;

export const Title = styled(TypographySubtitle)`
  color: ${Colors.background.white};
  opacity: .6;
  margin-bottom: 8px;
`;

export const TelegramIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const TelegramLink = styled.a`
  display: block;
  width: 32px;
  height: 32px;
  transition: opacity ease .25s;
  &:hover {
    opacity: .7;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

export const Email = styled.a`
  display: inline-block;
  margin-top: 12px;
  color: ${Colors.background.white};
  font-size: 14px;
  font-weight: 600;
  opacity: 1;
  transition: opacity, text-decoration-color ease .25s;
  text-decoration-line: underline;
  text-decoration-width: 4px;
  text-decoration-style: solid;
  text-decoration-color: ${Colors.background.white};
  &:hover {
    text-decoration-color: transparent;
  }
`;
