import styled from 'styled-components';
import { TypographySmallerText } from "theme/Thypography";
import { Colors } from 'theme/colors';
import { Breakpoints } from 'theme/breakpoints';

export const Wrapper = styled.div`
  @media screen and (max-width: ${Breakpoints.mobile}) {
    order: 2;
    margin-top: 24px;
  }
`;

export const Text = styled(TypographySmallerText)`
  color: ${Colors.background.white};
  margin-top: 16px;
  text-align: left;
  @media screen and (max-width: ${Breakpoints.mobile}) {
    text-align: center;
  }
`;
