import React, { EventHandler, FC } from 'react';
import { Container, BurgerBox, Burger } from './styles';

interface Props {
  onClick: EventHandler<any>;
}

export const HeaderBurger: FC<Props> = ({ onClick }) => (
  <Container onClick={onClick}>
    <BurgerBox>
      <Burger />
    </BurgerBox>
  </Container>
);


HeaderBurger.defaultProps = {
  onClick: undefined
};
