import styled, { css } from "styled-components";
import { Colors } from 'theme/colors';
import { TypographySmallerTitle, TypographySmallerText } from "../../../../theme/Thypography";
import { Breakpoints } from 'theme/breakpoints';

interface Props{
  reserved: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: -10px;
  flex-wrap: wrap;

  @media screen and (max-width: ${Breakpoints.tablet}) {
    margin: 0;
  }
`;

export const LeftBlock = styled.div`
  flex: 0 0 45%;
  max-width: 45%;
  padding: 10px;

  ${(props:Props) => props.reserved && css`
    order: 2;
  `}

  @media screen and (max-width: ${Breakpoints.tablet}) {
    padding: 0;
    flex: 0 0 80%;
    max-width: 80%;
    order: 2;
    margin: 24px auto 0 auto;
  }

  @media screen and (max-width: ${Breakpoints.mobile}) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

export const RightBlock = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  height: 400px;
  padding: 10px;
  border-radius: 12px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }

  ${(props:Props) => props.reserved && css`
    order: 1;
  `}

  @media screen and (max-width: ${Breakpoints.tablet}) {
    padding: 0;
    flex: 0 0 80%;
    max-width: 80%;
    order: 1;
    height: auto;
    margin: 0 auto;
  }
  @media screen and (max-width: ${Breakpoints.mobile}) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

export const SubtitleBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  @media screen and (max-width: ${Breakpoints.tablet}) {
    margin-bottom: 6px;
  }
`;

export const Subtitle = styled.h2`
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: ${Colors.text.lightBlue};
  @media screen and (max-width: ${Breakpoints.mobile}) {
   font-size: 16px;
   letter-spacing: 1px;
  }
`;

export const Label = styled.div`
 margin-left: 4px;
 padding: 1px 6px;
 background-color: ${Colors.background.green};
 font-size: 10px;
 border-radius: 4px;
 line-height: 13px;
 color: ${Colors.background.white};
 text-transform: lowercase;
`;

export const Title = styled(TypographySmallerTitle)`
  color: ${Colors.text.black};
  margin-bottom: 20px;
  @media screen and (max-width: ${Breakpoints.tablet}) {
    margin-bottom: 6px;
  }
`;

export const Text = styled(TypographySmallerText)`
  color: ${Colors.text.lightGrey};
  margin-bottom: 32px;

  p + p {
    padding-top: 24px;
  }

  ul {
    padding: 24px 0 24px 24px;
  }

  @media screen and (max-width: ${Breakpoints.tablet}) {
    margin-bottom: 12px;
  }
`;

export const LinkContainer = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: ${Colors.text.lightBlue};
  @media screen and (max-width: ${Breakpoints.mobile}) {
   font-size: 16px;
  }
`;

export const LinkIcon = styled.div`
  width: 24px;
  height: 24px;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const Link = styled.div`
  margin-left: 12px;
`;
