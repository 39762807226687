import React, { FC } from 'react';
import { Images } from 'utils/images';
import {
  Wrapper,
  Divider,
  AccordionTitle,
  AccordionDescription,
  AccordionDescriptionWrapper,
  AccordionDescriptionContainer,
  AccordionItem,
  AccordionIcon,
  AccordionContent
} from './styles';

export interface Item {
  id: string | number;
  title: string;
  icon?: string;
  descriptions: string[];
}

interface Props {
  items: Item [],
  activeTab: string | number;
  onChange: (id: string | number) => void;
}

export const Accordion: FC<Props> = ({ activeTab, items, onChange }) => {
  return (
    <Wrapper>
      {
        items.map((item: Item) => {
          return (
            <AccordionItem
              itemScope
              itemProp="mainEntity"
              itemType="https://schema.org/Question"
              key={item.id}
            >
              <AccordionTitle
                itemProp="name"
                onClick={() => onChange(item.id)}
                expanded={activeTab === item.id}
              >
                <AccordionContent>
                  {item.icon ? <AccordionIcon src={Images.services[item.icon]} alt="check" /> : null}
                  {item.title}
                </AccordionContent>
              </AccordionTitle>
              <AccordionDescriptionWrapper
                expanded={activeTab === item.id}
                itemScope
                itemProp="acceptedAnswer"
                itemType="https://schema.org/Answer"
              >
                <AccordionDescriptionContainer itemProp="text">
                  {
                    item.descriptions.map((description, index) => {
                      return (
                        <AccordionDescription key={index}>
                          {description}
                        </AccordionDescription>
                      );
                    })
                  }

                </AccordionDescriptionContainer>
              </AccordionDescriptionWrapper>
              <Divider />
            </AccordionItem>
          );
        })
      }
    </Wrapper>
  );
};

Accordion.defaultProps = {
  items: []
};
